<h2 mat-dialog-title>{{ title }}</h2>
<mat-dialog-content
  style="max-width: 500px"
  class="dialog-content mb-4">
  {{ content }}
</mat-dialog-content>
<mat-dialog-actions
  align="end"
  class="d-flex gap-2">
  <app-button
    variant="secondary"
    [disabled]="loading"
    mat-dialog-close>
    {{ cancelButtonText }}
  </app-button>
  <app-button
    [variant]="danger ? 'danger' : undefined"
    [loading]="loading"
    (click)="onConfirm()">
    {{ confirmationButtonText }}
  </app-button>
</mat-dialog-actions>
