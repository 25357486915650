import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { GalleryAdhocComponent } from './gallery-adhoc/gallery-adhoc.component';
import { GalleryObservationComponent } from './gallery-observation/gallery-observation.component';
import { GalleryRegularComponent } from './gallery-regular/gallery-regular.component';

@NgModule({
  imports: [RouterModule],
  declarations: [GalleryRegularComponent, GalleryAdhocComponent, GalleryObservationComponent],
  providers: [],
})
export class GalleryModule {}
