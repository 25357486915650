<h1>Export API</h1>
<br />
<h3>Projects</h3>
<p style="font-size: 15px; font-weight: bold">GET - /export/v1/Projects</p>
<textarea style="height: 200px; width: 100%">{{ projects }}</textarea>
<br />
<p style="font-size: 15px; font-weight: bold">GET - /export/v1/Projects/projectId</p>
<textarea style="height: 200px; width: 100%">{{ projectsById }}</textarea>

<br />
<h3>Adhoc Inspections</h3>
<p style="font-size: 15px; font-weight: bold">GET - /export/v1/Projects/projectId/adhocInspections</p>
<textarea style="height: 200px; width: 100%">{{ adhocs }}</textarea>
<br />
<p style="font-size: 15px; font-weight: bold">GET - /export/v1/Projects/projectId/adhocInspections/inspectionId</p>
<textarea style="height: 200px; width: 100%">{{ adhocById }}</textarea>
<br />
<p style="font-size: 15px; font-weight: bold">GET - /export/v1/Projects/projectId/adhocInspections/inspectionId/attachments</p>
<textarea style="height: 200px; width: 100%">{{ adhocAttachments }}</textarea>

<br />
<h3>Regular Inspections</h3>
<p style="font-size: 15px; font-weight: bold">GET - /export/v1/Projects/projectId/regularInspections</p>
<textarea style="height: 200px; width: 100%">{{ regulars }}</textarea>
<br />
<p style="font-size: 15px; font-weight: bold">GET - /export/v1/Projects/projectId/regularInspections/inspectionId</p>
<textarea style="height: 200px; width: 100%">{{ regularById }}</textarea>
<br />
<p style="font-size: 15px; font-weight: bold">GET - /export/v1/Projects/projectId/regularInspections/inspectionId/attachments</p>
<textarea style="height: 200px; width: 100%">{{ regularAttachments }}</textarea>
<br />
<h3>General Inspections</h3>
<p style="font-size: 15px; font-weight: bold">GET - /export/v1/Projects/projectId/generalInspections</p>
<textarea style="height: 200px; width: 100%">{{ generals }}</textarea>
<br />
<p style="font-size: 15px; font-weight: bold">GET - /export/v1/Projects/projectId/generalInspections/inspectionId</p>
<textarea style="height: 200px; width: 100%">{{ generalById }}</textarea>
<br />
<p style="font-size: 15px; font-weight: bold">GET - /export/v1/Projects/projectId/generalInspections/inspectionId/attachments</p>
<textarea style="height: 200px; width: 100%">{{ generalAttachments }}</textarea>
