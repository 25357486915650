import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { Entity } from '@app/models/entity';
import { UserAssignment } from '@app/models/userAssignment';
import { NotificationService } from '@app/services/notification.service';

import { GetAssignedProjects } from './userAssignments.action';
import { UserAssignmentsService } from './userAssignments.service';

export class ProjectPackageSubpackages {
  constructor(public project: Entity, public package_: Entity, public subpackages: Entity[]) {}
}

export type ProjectStateStatus = 'pending' | 'success' | 'failed' | 'loading';

export class ProjectStateModel {
  status: ProjectStateStatus;
  assignedProjects: UserAssignment[];
}

@State<ProjectStateModel>({
  name: 'projects',
  defaults: {
    status: 'pending',
    assignedProjects: [],
  },
})
@Injectable()
export class ProjectState {
  constructor(private userAssignmentsService: UserAssignmentsService, private notificationService: NotificationService) {}

  @Selector()
  static getProjects(state: ProjectStateModel): ProjectPackageSubpackages[] {
    const assignments: ProjectPackageSubpackages[] = [];
    const projectPackageSubpackages = new Map<string, UserAssignment[]>();
    for (const newUa of state.assignedProjects) {
      const key = `${newUa.project.shorthand}/${newUa.package.shorthand}`;
      if (projectPackageSubpackages.has(key)) {
        const uas = projectPackageSubpackages.get(key);
        const present = uas.some(ua => ua.subpackage.shorthand === newUa.subpackage.shorthand);
        if (!present) {
          uas.push(newUa);
          projectPackageSubpackages.set(key, uas);
        }
      } else {
        projectPackageSubpackages.set(key, [newUa]);
      }
    }
    projectPackageSubpackages.forEach(v => {
      assignments.push(
        new ProjectPackageSubpackages(
          v[0].project,
          v[0].package,
          v.map(ua => ua.subpackage)
        )
      );
    });
    return assignments;
  }

  @Selector()
  static getStatus({ status }: ProjectStateModel) {
    return status;
  }

  @Action(GetAssignedProjects)
  getUserAssignmentsFromService({ getState, setState }: StateContext<ProjectStateModel>) {
    const state = getState();
    setState({
      ...state,
      status: 'loading',
    });

    return this.userAssignmentsService.getAssignments().pipe(
      tap({
        next: result => {
          if (result === undefined || result.length === 0) {
            setState({
              ...state,
              assignedProjects: [],
              status: 'success',
            });
          } else {
            result.sort(this.sortByProjectPackageSubpackageShorthand);
            setState({
              ...state,
              assignedProjects: result,
              status: 'success',
            });
          }
        },
        error: error => {
          if (error.status === 401) {
            setState({
              ...state,
              status: 'failed',
            });
          }
        },
      })
    );
  }

  private sortByProjectPackageSubpackageShorthand = (a: UserAssignment, b: UserAssignment) => {
    if (a.project.shorthand < b.project.shorthand) {
      return -1;
    }
    if (a.project.shorthand > b.project.shorthand) {
      return 1;
    }
    if (a.project.shorthand === b.project.shorthand) {
      if (a.package.shorthand < b.package.shorthand) {
        return -1;
      }
      if (a.package.shorthand > b.package.shorthand) {
        return 1;
      }
      if (a.package.shorthand === b.package.shorthand) {
        if (a.subpackage.shorthand < b.subpackage.shorthand) {
          return -1;
        }
        if (a.subpackage.shorthand > b.subpackage.shorthand) {
          return 1;
        }
        return 0;
      }
    }
    return 0;
  };
}
