import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';

import { Roles } from './roles';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  private roleIdentifier = 'roles';
  private mappingIdentifier = 'mappings';

  constructor(private msalService: MsalService, private http: HttpClient, private router: Router) {
    if (this.authenticated) {
      this.authMappingMocked().subscribe(mappingData => {
        localStorage.setItem(this.mappingIdentifier, btoa(JSON.stringify(mappingData)));
      });
    }
  }

  get authenticated(): boolean {
    return this.msalService.instance.getAllAccounts().length > 0;
  }

  private authMappingMocked(): Observable<any> {
    const url = `../../../../assets/auth/authorization.mappings.json`;
    return this.http.get<any>(url);
  }

  public saveRoles(arrRoles: string[]) {
    const roles: Roles[] = [];

    arrRoles.forEach(role => {
      role = atob(role);
      roles.push(Roles[role]);
    });

    const encodedRoles = btoa(JSON.stringify(roles));
    sessionStorage.setItem(this.roleIdentifier, encodedRoles);
  }

  private getSavedRoles() {
    const rolesText = sessionStorage.getItem(this.roleIdentifier);

    if (rolesText === undefined || rolesText === null) {
      return null;
    }

    const decodedRoles = atob(rolesText);
    return JSON.parse(decodedRoles);
  }

  public isAuthorized(elementText: string) {
    let isAuthorized = false;

    const roles = this.getSavedRoles();

    elementText = elementText.toLowerCase();

    const decodedMappings = atob(localStorage.getItem(this.mappingIdentifier));
    const mappings = JSON.parse(decodedMappings);

    if (mappings[elementText] === undefined) {
      isAuthorized = false;
    } else if (mappings[elementText].includes('*')) {
      isAuthorized = true;
    } else if (roles === undefined || roles === null) {
      isAuthorized = false;
    } else {
      roles.forEach(role => {
        mappings[elementText].forEach(mappedRole => {
          if (mappedRole.toLowerCase() === role.toLowerCase()) {
            isAuthorized = true;
          }
        });
      });
    }

    return isAuthorized;
  }

  public isAdminAuthorized() {
    let isAuthorized = false;

    const roles = this.getSavedRoles();

    const decodedMappings = atob(localStorage.getItem(this.mappingIdentifier));
    const mappings = JSON.parse(decodedMappings);

    if (mappings['admins'] === undefined) {
      isAuthorized = false;
    } else if (mappings['admins'.toLowerCase()].includes('*')) {
      isAuthorized = true;
    } else if (roles === undefined || roles === null) {
      isAuthorized = false;
    } else {
      roles.forEach(role => {
        mappings['admins'.toLowerCase()].forEach(mappedRole => {
          if (mappedRole.toLowerCase() === role.toLowerCase()) {
            isAuthorized = true;
          }
        });
      });
    }

    return isAuthorized;
  }

  public hasSessionRole() {
    const roles = this.getSavedRoles();
    return !(roles === undefined || roles === null);
  }
}
