import { Component, OnInit } from '@angular/core';

import { AuthorizationService } from '@app/services/authorization/authorization.service';

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.scss'],
})
export class MasterComponent implements OnInit {
  constructor(public authorizationService: AuthorizationService) {}

  ngOnInit() {}
}
