<ng-container *ngIf="authorizationService.authenticated">
  <app-navbar></app-navbar>
</ng-container>
<ng-container *ngIf="!authorizationService.authenticated">
  <app-info-nav-bar></app-info-nav-bar>
</ng-container>
<div class="announcements">
  <h1>Announcements</h1>
  <div
    class="col-md-12 list"
    *ngFor="let announcement of announcements">
    <mdb-card>
      <mdb-card-body>
        <mdb-card-title>
          <img
            alt="icon"
            src="assets/images/info-icon.png"
            height="24" />
          <span class="title">
            {{ announcement.title }}
          </span>
        </mdb-card-title>
        <mdb-card-text>
          <span class="info">
            <div [innerHTML]="announcement.content"></div>
          </span>
        </mdb-card-text>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>
