import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthRoleGuard } from '@app/services/authorization/authorization.role.guard';

import { InfoFaqComponent } from './info-faq/info-faq.component';
import { FeedbackOverviewComponent } from './info-feedback/feedback-overview/feedback-overview.component';
import { InfoFeedbackComponent } from './info-feedback/info-feedback.component';
import { InfoListComponent } from './info-list/info-list.component';

export const InformationRoutes: Routes = [
  {
    path: '',
    redirectTo: 'list',
    pathMatch: 'full',
  },
  {
    path: 'list',
    component: InfoListComponent,
  },
  {
    path: 'faq',
    component: InfoFaqComponent,
  },
  {
    path: 'feedback',
    component: FeedbackOverviewComponent,
    canActivate: [AuthRoleGuard],
    data: {
      redirectTo: '/information/addFeedback',
    },
  },
  {
    path: 'addFeedback',
    component: InfoFeedbackComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(InformationRoutes)],
  exports: [RouterModule],
})
export class InformationRouteModule {}
