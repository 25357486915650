import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SnackbarComponent implements OnInit {
  status: string;
  statusCss: string;
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any[], private snackRef: MatSnackBarRef<SnackbarComponent>) {}

  ngOnInit() {
    switch (this.data[0].status) {
      case 'success': {
        this.status = 'info';
        this.statusCss = 'mdi success';
        break;
      }
      case 'error': {
        this.status = 'error';
        this.statusCss = 'mdi error';
        break;
      }
      default: {
        this.status = 'info';
        this.statusCss = 'mdi info';
        break;
      }
    }
  }
}
