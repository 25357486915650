import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from '@config';

import { Measurement } from './measurement';

@Injectable({
  providedIn: 'root',
})
export class MeasurementService {
  constructor(private http: HttpClient, private appConfig: AppConfig) {}
  getAllMeasurements(): Observable<Measurement[]> {
    const url = `${this.appConfig.apiUrl}/admin/v1/Measurements`;
    return this.http.get<Measurement[]>(url);
  }
  getMeasurementById(measurementId: string): Observable<Measurement> {
    const url = `${this.appConfig.apiUrl}/admin/v1/Measurements/${measurementId}`;
    return this.http.get<Measurement>(url);
  }
  createMeasurement(measurement: Measurement): Observable<boolean> {
    const url = `${this.appConfig.apiUrl}/admin/v1/Measurements/${measurement.id}`;
    return this.http.post<boolean>(url, measurement);
  }
  updateMeasurement(measurement: Measurement): Observable<boolean> {
    const url = `${this.appConfig.apiUrl}/admin/v1/Measurements/${measurement.id}`;
    return this.http.put<boolean>(url, measurement);
  }
  deleteMeasurement(measurementId: string): Observable<boolean> {
    const url = `${this.appConfig.apiUrl}/admin/v1/Measurements/${measurementId}`;
    return this.http.delete<boolean>(url);
  }
}
