import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Punchlist } from '@app/models/punchlist';
import { AppConfig } from '@config';

import { PunchlistService } from './punchlist.service';

enum InspectionType {
  Regular,
  AdHoc,
}

enum ItemType {
  Group,
  Item,
}

class Row {
  name: string;
  comment?: string;
  itemType: ItemType;
  inspectionType: InspectionType;
  inspectionId?: number;
  inspectionGrade?: string;
  inspectionSeverity?: string;
  imageIndices?: number[];
}

@Component({
  selector: 'app-punchlist',
  templateUrl: './punchlist.component.html',
  styleUrls: ['./punchlist.component.scss'],
})
export class PunchlistComponent implements OnInit {
  // So we can reference the enum inside the template.
  public ItemType = ItemType;
  punchlist: Punchlist;
  rows: Row[];
  imageUrls: string[];

  constructor(private punchlistService: PunchlistService, private route: ActivatedRoute, public appConfig: AppConfig) {}

  ngOnInit() {
    const checklistId = this.route.snapshot.params.checklistId;
    this.rows = [];
    this.imageUrls = [];

    this.punchlistService.getPunchlist(checklistId).subscribe(res => {
      for (const g of res.regularGroups) {
        this.rows.push({
          name: g.name,
          itemType: ItemType.Group,
          inspectionType: InspectionType.Regular,
        });

        for (const r of g.items) {
          this.rows.push({
            inspectionId: r.inspectionId,
            name: r.name,
            comment: r.comment,
            inspectionGrade: r.inspectionGrade,
            inspectionSeverity: r.inspectionSeverity,
            itemType: ItemType.Item,
            inspectionType: InspectionType.Regular,
            imageIndices: this.updateImages(r.inspection, InspectionType.Regular),
          });
        }
      }

      // TODO: adHocGroup => adHocInspectionItems?
      if (res.adHocGroup.items.length > 0) {
        this.rows.push({
          name: 'Ad hoc inspections',
          itemType: ItemType.Group,
          inspectionType: InspectionType.AdHoc,
        });

        for (const a of res.adHocGroup.items) {
          this.rows.push({
            name: a.name,
            comment: a.comment,
            inspectionGrade: a.inspectionGrade,
            inspectionSeverity: a.inspectionSeverity,
            itemType: ItemType.Item,
            inspectionType: InspectionType.AdHoc,
            imageIndices: this.updateImages(a.inspection, InspectionType.AdHoc),
          });
        }
      }
      this.punchlist = res;
    });
  }

  updateImages(inspection: any, inspectionType: InspectionType): number[] {
    let indices = null;
    if (inspection !== null) {
      if (inspection.inspectionPhotoMetadata.length > 0) {
        indices = [];
        for (const i of inspection.inspectionPhotoMetadata) {
          indices.push(this.imageUrls.length);

          let part = null;
          switch (inspectionType) {
            case InspectionType.Regular:
              part = 'regular';
              break;
            case InspectionType.AdHoc:
              part = 'adhoc';
              break;
            default:
              throw new Error('Unsupported InspectionType: ' + inspection.inspectionType);
          }

          this.imageUrls.push(
            `${this.appConfig.apiUrl}/admin/v1/checklists/${i.checklistId}/${part}inspections/${i.inspectionId}/InspectionPhotos/${i.inspectionPhotoId}`
          );
        }
      }
    }
    return indices;
  }

  renderState(inspectionGrade: any) {
    return inspectionGrade === null ? 'N/A' : inspectionGrade;
  }
}
