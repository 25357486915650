import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private breadCrumbString: string;
  constructor() {}

  setBreadCrumbs(projectName: string, packageName: string, subPackageName: string) {
    if (projectName !== undefined) {
      const breadcrumbs = {
        project: projectName,
        package: packageName,
        subPackage: subPackageName,
      };
      this.breadCrumbString = breadcrumbs.project;

      if (breadcrumbs.package) {
        this.breadCrumbString += ` > ${breadcrumbs.package}`;

        if (breadcrumbs.subPackage) {
          this.breadCrumbString += ` > ${breadcrumbs.subPackage}`;
        }
      }

      localStorage.setItem('breadcrumbs', this.breadCrumbString);
    }
  }

  getBreadCrumbs(): string {
    if (localStorage.getItem('breadcrumbs') !== undefined) {
      return localStorage.getItem('breadcrumbs');
    } else if (this.breadCrumbString !== undefined) {
      return this.breadCrumbString;
    }
  }
}
