@if (!!statistics) {
<div class="statistics">
  <ul class="statistics__list">
    @for (item of statistics; track item) {
    <li class="statistics__item">
      <div class="statistics__value">{{ item.Value }}</div>
      <span class="statistics__text">{{ item.Text }}</span>
    </li>
    }
  </ul>
</div>
}
