import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MsalService } from '@azure/msal-angular';

import { AuthorizationService } from '@app/services/authorization/authorization.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  constructor(private msalService: MsalService, public authorizationService: AuthorizationService, private dialog: MatDialog) {}

  ngOnInit() {}

  logout() {
    this.msalService.logout();
    sessionStorage.clear();
  }

  get userName(): string {
    let activeAccount = this.msalService.instance.getActiveAccount();
    if (!activeAccount && this.authorizationService.authenticated) {
      const accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
      activeAccount = this.msalService.instance.getActiveAccount();
    }
    return activeAccount.username.split('@')[0];
  }
}
