import { Component, OnInit } from '@angular/core';

import { ProjectService } from '@features/assignment/user-assignment/project.service';

@Component({
  selector: 'app-export-api',
  templateUrl: './export-api.component.html',
  styleUrls: ['./export-api.component.scss'],
})
export class ExportApiComponent implements OnInit {
  projects: string;
  projectsById: string;
  adhocs: string;
  adhocById: string;
  adhocAttachments: string;
  regulars: string;
  regularById: string;
  regularAttachments: string;
  generals: string;
  generalById: string;
  generalAttachments: string;

  constructor(private projectService: ProjectService) {}

  ngOnInit() {
    this.projectService.getAPIProjects().subscribe(res => (this.projects = JSON.stringify(res)));
    this.projectService.getAPIProjectById().subscribe(res => (this.projectsById = JSON.stringify(res)));
    this.projectService.getAPIAdhocInspections().subscribe(res => (this.adhocs = JSON.stringify(res)));
    this.projectService.getAPIAdhocInspectionById().subscribe(res => (this.adhocById = JSON.stringify(res)));
    this.projectService.getAPIAdhocInspectionAttachmentById().subscribe(res => (this.adhocAttachments = JSON.stringify(res)));
    this.projectService.getAPIRegularInspections().subscribe(res => (this.regulars = JSON.stringify(res)));
    this.projectService.getAPIRegularInspectionById().subscribe(res => (this.regularById = JSON.stringify(res)));
    this.projectService.getAPIRegularInspectionAttachmentById().subscribe(res => (this.regularAttachments = JSON.stringify(res)));
    this.projectService.getAPIGeneralInspections().subscribe(res => (this.generals = JSON.stringify(res)));
    this.projectService.getAPIGeneralInspectionById().subscribe(res => (this.generalById = JSON.stringify(res)));
    this.projectService.getAPIGeneralInspectionAttachmentById().subscribe(res => (this.generalAttachments = JSON.stringify(res)));
  }
}
