<h2
  mat-dialog-title
  class="dialog-title">
  {{ data.title }}
</h2>
<mat-dialog-content class="dialog-title">
  <section [formGroup]="formMeasurementMaster">
    <div class="form-group">
      <label
        for="shorthand"
        class="d-block form-label">
        Shorthand
      </label>
      <input
        class="form-control"
        text
        formControlName="shorthand"
        type="text" />
      <span
        error
        *ngIf="
          formMeasurementMaster.controls['shorthand'].hasError('pattern') &&
          (formMeasurementMaster.controls['shorthand'].dirty || formMeasurementMaster.controls['shorthand'].touched)
        ">
        Shorthand should be in a valid pattern. (e.i) ABC01
      </span>
      <span
        error
        *ngIf="
          formMeasurementMaster.controls['shorthand'].hasError('required') &&
          (formMeasurementMaster.controls['shorthand'].dirty || formMeasurementMaster.controls['shorthand'].touched)
        ">
        Shorthand is required.
      </span>
    </div>

    <div class="form-group">
      <label
        for="name"
        class="d-block form-label">
        Name
      </label>
      <input
        class="form-control"
        text
        formControlName="name"
        type="text" />
      <span
        error
        *ngIf="
          formMeasurementMaster.controls['name'].hasError('required') &&
          (formMeasurementMaster.controls['name'].dirty || formMeasurementMaster.controls['name'].touched)
        ">
        Name is required.
      </span>
    </div>

    <div class="form-group">
      <label
        for="additionalInfo"
        class="d-block form-label">
        Additional Information
      </label>
      <textarea
        class="form-control"
        formControlName="additionalInfo"
        type="text"></textarea>
    </div>

    <div class="form-group">
      <label
        for="unit"
        class="d-block form-label">
        Unit
      </label>
      <input
        class="form-control"
        text
        formControlName="unit"
        type="text" />
    </div>
  </section>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button
    dialog-button
    type="submit"
    (click)="submit()">
    Submit
  </button>
  <button
    dialog-button
    mat-dialog-close
    class="secondary-button">
    Close
  </button>
</mat-dialog-actions>
