<mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark orsted-blue">
  <mdb-navbar-brand>
    <a
      class="navbar-brand"
      href="#">
      <img
        src="assets/images/dit-logo_64x64.png"
        height="42"
        alt="Orsted Logo" />
      <span class="nav-logo-title">Digital Inspection Tool</span>
    </a>
  </mdb-navbar-brand>
  <links>
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a
          class="nav-link navigation-border white-text"
          [routerLink]="['/information/list']"
          routerLinkActive="navigation-border-selected"
          mdbWavesEffect>
          Announcements
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link navigation-border white-text"
          [routerLink]="['/information/faq']"
          routerLinkActive="navigation-border-selected"
          mdbWavesEffect>
          FAQs
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link navigation-border white-text"
          [routerLink]="['/information/addFeedback']"
          routerLinkActive="navigation-border-selected"
          mdbWavesEffect>
          Feedback
        </a>
      </li>
    </ul>
  </links>
</mdb-navbar>
