<div class="container">
  <title>Measuring Points Overview</title>
  <div class="actions">
    <button
      mdbWavesEffect
      (click)="create()">
      Add Measuring Point
    </button>
    <span class="spacer"></span>
    <input
      type="search"
      placeholder="Search"
      (input)="onSearch($event.target.value)" />
  </div>
  <ag-grid-angular
    style="width: 100%"
    #agGrid
    id="myGrid"
    class="ag-theme-balham"
    [rowData]="measurements$ | async"
    [columnDefs]="columnDefs"
    [suppressContextMenu]="true"
    [defaultColDef]="defaultColDef"
    [domLayout]="domLayout"
    (gridReady)="onGridReady($event)"
    (gridSizeChanged)="onGridSizeChanged()"
    [components]="frameworkComponents"
    [context]="this"
    (cellClicked)="onCellClicked($event)"></ag-grid-angular>
</div>
