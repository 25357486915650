<app-navbar></app-navbar>
<div class="container">
  <title>Feedback Overview</title>
  <div class="actions">
    <button
      (click)="addFeedback()"
      mdbWavesEffect>
      Add Feedback
    </button>
    <span class="spacer"></span>
    <input
      type="search"
      placeholder="Search"
      (input)="searchItem($event.target.value)" />
  </div>
  <ag-grid-angular
    style="width: 100%"
    #agGrid
    id="feedback-overview-grid"
    class="ag-theme-balham"
    [rowData]="feedbacks"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [domLayout]="domLayout"
    [pagination]="true"
    [paginationPageSizeSelector]="false"
    [paginationPageSize]="50"
    [suppressContextMenu]="true"
    (gridReady)="onGridReady($event)"
    [context]="this"
    (gridSizeChanged)="onGridSizeChanged()"
    rowGroupPanelShow="always"
    [animateRows]="true"
    [autoGroupColumnDef]="autoGroupColumnDef"
    [popupParent]="popupParent"
    (columnVisible)="onColumnVisible()"></ag-grid-angular>
</div>
