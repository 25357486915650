import { trigger, state, style, transition, animate } from '@angular/animations';
import { NgClass, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, MatIcon],
  animations: [trigger('fadeOut', [state('void', style({ opacity: 0 })), transition(':leave', animate('300ms', style({ opacity: 0 })))])],
})
export class AlertComponent implements OnInit {
  @Input() color: 'default' | 'warn' = 'default';

  @Input() dismissible = false;

  closed = false;

  constructor() {}

  ngOnInit() {}

  onCloseClick() {
    this.closed = true;
  }
}
