import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { SharedModule } from '@shared/shared.module';

import { MasterComponent } from './master.component';
import { MasterRoutesModule } from './master.routing';
import { MeasurementDetailDialogComponent } from './measurements/measurement-detail-dialog/measurement-detail-dialog.component';
import { MeasurementsComponent } from './measurements/measurements.component';
import { MeasurementState } from './measurements/measurements.state';

@NgModule({
  imports: [SharedModule, MasterRoutesModule, NgxsModule.forFeature([MeasurementState])],
  declarations: [MeasurementsComponent, MasterComponent, MeasurementDetailDialogComponent],
  providers: [],
})
export class MasterModule {}
