import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from '@config';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  constructor(private http: HttpClient, private appConfig: AppConfig) {}

  getFile(exporterType: string, fileType: string, fileId: string): Observable<Blob> {
    const url = `${this.appConfig.apiUrl}/admin/v1/Files/${exporterType}/${fileType}/${fileId}`;
    return this.http.get(url, { responseType: 'blob' });
  }
}
