<app-navbar></app-navbar>
<div
  class="sandlight25"
  *ngIf="authorizationService.authenticated">
  <div class="container">
    <nav class="nav lighten-4">
      <a
        class="nav-link subnavigation-border black-text"
        [routerLink]="['measurements']"
        routerLinkActive="subnavigation-border-selected">
        Measuring Points
      </a>
    </nav>
  </div>
</div>
<router-outlet></router-outlet>
