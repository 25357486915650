import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

import { MasterComponent } from './master.component';
import { MeasurementsComponent } from './measurements/measurements.component';

export const MasterRoutes: Routes = [
  {
    path: '',
    component: MasterComponent,
    canActivate: [MsalGuard],
    children: [
      {
        path: '',
        redirectTo: 'measurements',
        pathMatch: 'full',
      },
      {
        path: 'measurements',
        component: MeasurementsComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(MasterRoutes)],
  exports: [RouterModule],
})
export class MasterRoutesModule {}
