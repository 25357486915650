import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { Entity } from '@app/models/entity';
import { StorageService } from '@app/services/authentication/storage.service';
import { AuthorizationService } from '@app/services/authorization/authorization.service';
import { BreadcrumbService } from '@shared/breadcrumb/breadcrumb.service';

import { UserService } from '@features/assignment/user-assignment/user.service';

import { GetAssignedProjects } from './userAssignments.action';
import { ProjectPackageSubpackages, ProjectState, ProjectStateStatus } from './userAssignments.state';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  @Select(ProjectState.getStatus) status$: Observable<ProjectStateStatus>;
  @Select(ProjectState.getProjects) projects$: Observable<ProjectPackageSubpackages[]>;

  constructor(
    private store: Store,
    private userService: UserService,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private msalService: MsalService,
    public authorizationService: AuthorizationService,
    private storageService: StorageService
  ) {}

  get userName() {
    let activeAccount = this.msalService.instance.getActiveAccount();
    if (!activeAccount && this.authorizationService.authenticated) {
      const accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
      activeAccount = this.msalService.instance.getActiveAccount();
    }
    return activeAccount.username;
  }

  savingCurrentRoles() {
    if (!this.authorizationService.authenticated) {
      this.router.navigate(['']);
      return;
    }

    this.userService.getAdUser(this.userName).subscribe({
      next: userDetails => {
        this.authorizationService.saveRoles(userDetails.roles);
      },
    });
  }

  urlRedirect() {
    const galleryUrl = this.storageService.getData('galleryUrl');
    const downloadUrl = this.storageService.getData('downloadUrl');
    if (galleryUrl !== undefined && galleryUrl !== null) {
      this.storageService.removeData('galleryUrl');
      this.router.navigateByUrl(encodeURI(galleryUrl));
    } else if (downloadUrl !== undefined && downloadUrl !== null) {
      this.storageService.removeData('downloadUrl');
      this.router.navigateByUrl(encodeURI(downloadUrl));
    }
  }

  ngOnInit() {
    this.savingCurrentRoles();

    this.urlRedirect();

    this.store.dispatch(new GetAssignedProjects());
  }

  onProjectClick(projectpackage: ProjectPackageSubpackages, subpackage: Entity) {
    this.breadcrumbService.setBreadCrumbs(projectpackage.project.shorthand, projectpackage.package_.shorthand, subpackage.shorthand);
    this.router.navigate(['/assignment', projectpackage.project.id, projectpackage.package_.id, subpackage.id]);
  }
}
