import { Measurement } from './measurement';

export const SELECT_MEASUREMENT = '[Measurements] Select';
export const GET_ALL_MEASUREMENTS = '[Measurements] GetAll';
export const CREATE_MEASUREMENT = '[Measurements] Create';
export const UPDATE_MEASUREMENT = '[Measurements] Update';
export const DELETE_MEASUREMENT = '[Measurements] Delete';

export interface MeasurementPayloadModel extends Measurement {}

export class SelectMeasurement {
  static readonly type = SELECT_MEASUREMENT;
  constructor(public payload: { measurementId: string }) {}
}

export class GetMeasurements {
  static readonly type = GET_ALL_MEASUREMENTS;
  constructor() {}
}

export class CreateMeasurement {
  static readonly type = CREATE_MEASUREMENT;
  constructor(public payload: MeasurementPayloadModel) {}
}

export class UpdateMeasurement {
  static readonly type = UPDATE_MEASUREMENT;
  constructor(public payload: MeasurementPayloadModel) {}
}

export class DeleteMeasurement {
  static readonly type = DELETE_MEASUREMENT;
  constructor(public payload: { measurementId: string }) {}
}
