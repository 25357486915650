import { Component, OnInit } from '@angular/core';

import { AuthorizationService } from '@app/services/authorization/authorization.service';
export class FAQ {
  title: string;
  content: string;
  id: number;
  class: string;
  link: string;
  color: string;
}

@Component({
  selector: 'app-info-faq',
  templateUrl: './info-faq.component.html',
  styleUrls: ['./info-faq.component.scss'],
})
export class InfoFaqComponent implements OnInit {
  FAQs: FAQ[] = [];
  defaultFAQs = [
    {
      id: 1,
      class: 'faq-1',
      color: 'odd',
      link: 'information/faq#faq-1',
      title: 'I cannot find the DIT app in Ørsted AppStore',
      content:
        'First make sure that you have applied access to the correct AD group via OneWebShop (OWS). Secondly reach out to the AD group Owner if he/she has approved your request. Please note that it can take up to one day for the AD group change to take effect. See info about available AD groups on the Vital MyTools page for DIT, or ask your Scope Manager.',
    },
    {
      id: 2,
      class: 'faq-2',
      color: 'odd',
      link: 'information/faq#faq-2',
      title: 'How can I try out DIT before I need to use DIT in a real project?',
      content:
        'A test project is available in the production version of DIT. It is called DIT_UT (short for User Training). Please ask your Scope Manager to grant you access to DIT_UT.',
    },
    {
      id: 3,
      class: 'faq-3',
      color: 'odd',
      link: 'information/faq#faq-3',
      title: 'When I open the APP the Projects list is empty',
      content: 'Contact your Scope Manager and make sure that you have been assigned to a project.',
    },
    {
      id: 4,
      class: 'faq-4',
      color: 'odd',
      link: 'information/faq#faq-4',
      title: 'I have problems synchronizing what do I do?',
      content:
        'Ensure you have decent internet connection. Wait a little while and try again. If the problem persists contact <b>mobile@orsted.dk</b> to get assistance.',
    },
    {
      id: 5,
      class: 'faq-5',
      color: 'odd',
      link: 'information/faq#faq-5',
      title: 'I cannot choose an uploaded checklist template, when creating a new checklist.',
      content: 'Ask your Scope Manager he/she to change status from draft to released on the template',
    },
    {
      id: 6,
      class: 'faq-6',
      color: 'odd',
      link: 'information/faq#faq-6',
      title: 'I cannot find the right Contractor in the list, when assigning.',
      content:
        'Go to the feedback form in the frontpage and select request (in feedback types) and select database in the category. <br/>Write a title e.g. “New contractor”. <br/>In the description,  request the new contractor. Contractor name consist of a Short name followed by a long Long Name <br/>Contractor name is often given by Contractors own abbreviation. ',
    },
    {
      id: 7,
      class: 'faq-7',
      color: 'odd',
      link: 'information/faq#faq-7',
      title: 'I cannot find the right location in the list, when assigning.',
      content:
        'Go to the feedback form in the frontpage and select request (in feedback types) and select database in the category. <br/>Write a title e.g. “New Location”. <br/>Location is given from International code list for Country and territory. Use the following link <a href="https://www.unece.org/cefact/locode/service/location">https://www.unece.org/cefact/locode/service/location</a>.  <br/>Select Country and Look up Territory name and pick the LO code <br/>In the description, request the new LO code. ',
    },
    {
      id: 8,
      class: 'faq-8',
      color: 'odd',
      link: 'information/faq#faq-8',
      title: 'How do I change a checklist template?',
      content: 'This is the responsibility of the scope manager and should be discussed with him/her.',
    },
    {
      id: 9,
      class: 'faq-9',
      color: 'odd',
      link: 'information/faq#faq-9',
      title: 'I wrongly pressed OK to a checklist item, how do I get it back?',
      content:
        'Go to the checklist, press the three dots in upper right corner. Now go to the history and choose the item you just clicked OK. You can now mark it as NOK. Perhaps set a comment that it was by a mistake set to ok.',
    },
  ];
  constructor(public authorizationService: AuthorizationService) {}

  ngOnInit() {
    this.getFAQs();
  }

  getFAQs() {
    this.FAQs = this.defaultFAQs;
  }
}
