import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ICellRendererAngularComp } from 'ag-grid-angular';

import { Checklist, ChecklistStatus } from '@app/models/checklist';
import { AuthorizationService } from '@app/services/authorization/authorization.service';
@Component({
  selector: 'app-button-cell',
  templateUrl: './btn-render.component.html',
  styleUrls: ['./btn-render.component.scss'],
})
export class ButtonRenderComponent implements ICellRendererAngularComp {
  constructor(public authorizationService: AuthorizationService, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon('trash', sanitizer.bypassSecurityTrustResourceUrl('assets/images/trash-can-solid.svg'));
    iconRegistry.addSvgIcon('check', sanitizer.bypassSecurityTrustResourceUrl('assets/images/circle-check-regular.svg'));
    iconRegistry.addSvgIcon('right', sanitizer.bypassSecurityTrustResourceUrl('assets/images/circle-right-regular.svg'));
    iconRegistry.addSvgIcon('menu', sanitizer.bypassSecurityTrustResourceUrl('assets/images/ellipsis-vertical-solid.svg'));
  }

  params;
  row: Checklist;

  agInit(params): void {
    this.params = params;
    this.row = params.node.data;
  }

  refresh(_params?: any): boolean {
    return true;
  }

  deleteChecklist() {
    this.params.delete(this.row, 'Delete', ChecklistStatus.Deleted);
  }

  completeChecklist() {
    this.params.complete(this.row, 'Complete', ChecklistStatus.Completed);
  }

  details() {
    this.params.details(this.row);
  }
}
