/* eslint-disable @angular-eslint/no-output-native */
import { NgIf } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, Output } from '@angular/core';

import '@oxygen/components/lib/button';
import { BadgeComponent } from '../badge/badge.component';

/**
 * Wrapper component for the Oxygen Button component.
 */
@Component({
  standalone: true,
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  imports: [NgIf, BadgeComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ButtonComponent {
  @Input() disabled: boolean = false;

  @Input() loading: boolean = false;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input({ alias: 'variant' }) _variant: 'primary' | 'secondary' | 'link' | 'danger' = 'primary';

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input({ alias: 'size' }) _size: 'small' | 'medium' | 'large' = 'medium';

  @Input() badge: string;

  @Output() click: EventEmitter<Event> = new EventEmitter<Event>();

  get variant() {
    switch (this._variant) {
      case 'primary':
        return 'primary';
      case 'secondary':
        return 'secondary';
      case 'link':
        return 'ghost';
      case 'danger':
        return 'danger';
      default:
        return 'primary';
    }
  }

  get size() {
    return this._size;
  }

  onClick(event: Event) {
    event.stopPropagation();
    this.click.emit(event);
  }
}
