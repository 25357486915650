import { Component, Input, OnChanges } from '@angular/core';

import { NgxGalleryAnimation, NgxGalleryImage, NgxGalleryOptions } from '@shared/ngx-gallery';

@Component({
  selector: 'app-generic-image-gallery',
  templateUrl: './generic-image-gallery.component.html',
  styleUrls: ['./generic-image-gallery.component.scss'],
})
export class GenericImageGalleryComponent implements OnChanges {
  @Input()
  images: [
    {
      big: string | ArrayBuffer;
      medium: string | ArrayBuffer;
      small: string | ArrayBuffer;
    }
  ] = null;
  galleryImages: NgxGalleryImage[] = [];
  galleryOptions: NgxGalleryOptions[];

  constructor() {
    this.galleryOptions = [
      {
        imageAnimation: NgxGalleryAnimation.Slide,
      },
      { image: false, thumbnailsRemainingCount: true, height: '100px' },
      { breakpoint: 500, width: '100%', thumbnailsColumns: 2 },
    ];
  }
  ngOnChanges() {
    this.galleryImages = this.images;
  }
}
