if (!window?.dit?.config) {
  throw new Error('Missing DIT Config');
}

export const environment = {
  agGrid: {
    // cspell:disable
    license:
      'Using_this_{AG_Grid}_Enterprise_key_{AG-066950}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Orsted_Services_A/S}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{DIT}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{DIT}_need_to_be_licensed___{DIT}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{8_October_2025}____[v3]_[01]_MTc1OTg3ODAwMDAwMA==102481ac6ce0a18bfde4f83e08e3ea89',
    // cspell:enable
  },
  ...window.dit.config,
};
