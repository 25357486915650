<div class="container">
  <div
    class="mb-3"
    *ngIf="!punchlist">
    Loading...
  </div>

  <div style="color: crimson">
    IMPORTANT: image previewing in this punchlist is broken. Issue will not be fixed in this HTML punchlist as it'll be replaced by an Excel
    version.
  </div>

  &nbsp; &nbsp;

  <div
    class="mb-3"
    *ngIf="punchlist">
    <div class="row">
      <div class="col-sm-2">
        <strong>Project:</strong>
      </div>
      <div class="col-sm-10">{{ punchlist.project.shorthand }} / {{ punchlist.project.name }}</div>
    </div>

    <div class="row">
      <div class="col-sm-2">
        <strong>Package:</strong>
      </div>
      <div class="col-sm-10">{{ punchlist.package.shorthand }} / {{ punchlist.package.name }}</div>
    </div>

    <div class="row">
      <div class="col-sm-2">
        <strong>Subpackage:</strong>
      </div>
      <div class="col-sm-10">{{ punchlist.subpackage.shorthand }} / {{ punchlist.subpackage.name }}</div>
    </div>

    <div class="row">
      <div class="col-sm-2">
        <strong>Contractor:</strong>
      </div>
      <div class="col-sm-10">{{ punchlist.contractor.shorthand }} / {{ punchlist.contractor.name }}</div>
    </div>

    <div class="row">
      <div class="col-sm-2">
        <strong>Location:</strong>
      </div>
      <div class="col-sm-10">{{ punchlist.location.shorthand }} / {{ punchlist.location.country }} / {{ punchlist.location.city }}</div>
    </div>

    <div class="row">
      <div class="col-sm-2">
        <strong>Component type:</strong>
      </div>
      <div class="col-sm-10">{{ punchlist.componentTypeShorthand }} / {{ punchlist.componentTypeName }}</div>
    </div>

    <div class="row">
      <div class="col-sm-2">
        <strong>Checklist type:</strong>
      </div>
      <div class="col-sm-10">{{ punchlist.checklistTypeShorthand }} / {{ punchlist.checklistTypeName }}</div>
    </div>

    <div class="row">
      <div class="col-sm-2">
        <strong>Orsted id:</strong>
      </div>
      <div class="col-sm-10">
        {{ punchlist.componentOrstedIdentifier }}
      </div>
    </div>

    <div class="row">
      <div class="col-sm-2">
        <strong>Contractor id:</strong>
      </div>
      <div class="col-sm-10">
        {{ punchlist.componentContractorIdentifier }}
      </div>
    </div>

    &nbsp;

    <div class="row md-12">
      <table
        mdbTable
        striped="true">
        <thead class="black white-text">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Images</th>
            <th scope="col">State</th>
            <th scope="col">Comment</th>
          </tr>
        </thead>
        <tbody>
          <tr
            mdbTableCol
            *ngFor="let r of rows">
            <th scope="row">{{ r.inspectionId }}</th>
            <td *ngIf="r.itemType === ItemType.Group">
              <h4>{{ r.name }}</h4>
            </td>
            <td *ngIf="r.itemType === ItemType.Item">{{ r.name }}</td>
            <td *ngIf="r.imageIndices?.length > 0">
              <span *ngFor="let i of r.imageIndices">
                {{ i + 1 }}
              </span>
            </td>
            <td *ngIf="r.imageIndices === null || r.itemType === ItemType.Group">&nbsp;</td>
            <td *ngIf="r.itemType === ItemType.Item">{{ renderState(r.inspectionGrade) }}</td>
            <td>{{ r.comment }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>&nbsp;</div>
    <div>&nbsp;</div>

    <div
      class="row"
      *ngFor="let url of imageUrls; index as idx">
      [{{ idx + 1 }}] &nbsp;
      <!-- TODO: Can't open in blank because that causes the token to not get
            send across resulting in a "claims missing" error message.
            Instead, create an separate Angular URL with the tags below
            but without height and width. -->
      <!-- <a target="_blank" [href]="url"> -->
      <app-secured-image
        [src]="url"
        height="200"
        width="200"></app-secured-image>
      <!-- </a> -->
    </div>
  </div>
</div>
