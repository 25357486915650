import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { SkeletonModule } from 'primeng/skeleton';
import { Observable, of } from 'rxjs';

import { SimpleChangesTyped } from '@app/common/angular';

type Item = { shorthand?: string; name: string };

@Component({
  selector: 'app-detail-entity',
  templateUrl: './detail-entity.component.html',
  standalone: true,
  imports: [CommonModule, SkeletonModule],
})
export class DetailEntityComponent implements OnChanges {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input({ required: true }) item: Item | Observable<Item>;
  @Input() badgeClass?: string;

  ngOnChanges(changes: SimpleChangesTyped<DetailEntityComponent>): void {
    if (!(changes.item.currentValue instanceof Observable)) {
      this.item = of(changes.item.currentValue);
    }
  }
}
