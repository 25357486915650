<div class="overview-card">
  <ng-container [ngTemplateOutlet]="headerTemplate || defaultHeaderTemplate"> </ng-container>
  <div class="overview-card__body">
    <ng-content></ng-content>
  </div>
</div>

<ng-template #defaultHeaderTemplate>
  <ng-container *ngIf="headerTitle && !headerTitleTemplate">
    <div class="overview-card__header">
      <div class="overview-card__header-title">{{ headerTitle }}</div>
      <ng-container [ngTemplateOutlet]="headerOptionWrapper"></ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="headerTitleTemplate">
    <div class="overview-card__header">
      <div class="overview-card__header-title">
        <ng-container [ngTemplateOutlet]="headerTitleTemplate"></ng-container>
      </div>
      <ng-container [ngTemplateOutlet]="headerOptionWrapper"></ng-container>
    </div>
  </ng-container>
</ng-template>

<ng-template #headerOptionWrapper>
  <div
    *ngIf="headerOptionTemplate"
    class="overview-card__header-option">
    <ng-container [ngTemplateOutlet]="headerOptionTemplate"></ng-container>
  </div>
</ng-template>
