import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-detail-label',
  templateUrl: './detail-label.component.html',
  styleUrl: './detail-label.component.scss',
  standalone: true,
  imports: [CommonModule],
})
export class DetailLabelComponent {
  @Input() label: string | null = null;
  @Input() labelClass?: string = '';
}
