import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthorizationService } from '@app/services/authorization/authorization.service';
import { NotificationService } from '@app/services/notification.service';

import { Feedback } from './feedback';
import { FeedbackCategory } from './feedback-category';
import { FeedbackStatus } from './feedback-status';
import { FeedbackType } from './feedback-type';
import { InfoFeedbackService } from './info-feedback.service';

@Component({
  selector: 'app-info-feedback',
  templateUrl: './info-feedback.component.html',
  styleUrls: ['./info-feedback.component.scss'],
})
export class InfoFeedbackComponent implements OnInit {
  title: string;
  description: string;
  email: string;
  selectedFeedbackType: FeedbackType;
  selectedCategory: FeedbackCategory;
  newStatus: FeedbackStatus = FeedbackStatus.New;
  photos: File[] = [];
  tempPhotos: {
    big: string | ArrayBuffer;
    medium: string | ArrayBuffer;
    small: string | ArrayBuffer;
  }[] = [];
  feedbackFromApp = false;
  usernameFromApp: string;

  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private feedbackService: InfoFeedbackService,
    private _location: Location,
    private activatedRoute: ActivatedRoute,
    public authorizationService: AuthorizationService
  ) {
    this.activatedRoute.queryParamMap.subscribe(params => {
      if (params.keys.length > 0) {
        const paramsKeys = { ...params.keys };
        const decodedParams = atob(paramsKeys[0]);
        const queryParams: string[] = decodedParams.split('&');
        const categoryParam: string[] = queryParams[0].split('=');
        const userNameParam = queryParams[1].split('=');
        this.feedbackFromApp = categoryParam[1] === FeedbackCategory[FeedbackCategory.App];
        this.usernameFromApp = userNameParam[1];
      }
    });
  }

  ngOnInit() {
    this.selectedFeedbackType = FeedbackType.Proposal;
    this.selectedCategory = this.feedbackFromApp ? FeedbackCategory.App : FeedbackCategory.Web;
  }

  categoryChange($data) {
    this.selectedCategory = $data.value;
  }

  feedbackTypeChange($data) {
    this.selectedFeedbackType = $data.value;
  }

  isFeedbackTypeProblem() {
    return this.selectedFeedbackType.toString() === FeedbackType[FeedbackType.Problem];
  }

  validate(): boolean {
    if (this.selectedFeedbackType === undefined || this.selectedFeedbackType === null) {
      this.notificationService.error('Please select a valid feedback type.');
      return false;
    } else if (this.selectedCategory === undefined || this.selectedCategory === null) {
      this.notificationService.error('Please select a valid feedback category.');
      return false;
    } else if (this.title === undefined || this.title === '') {
      this.notificationService.error('Please have a valid title.');
      return false;
    } else if (this.description === undefined || this.description === '') {
      this.notificationService.error('Please have a valid description.');
      return false;
    } else if (this.feedbackFromApp ? !this.validateEmail(this.usernameFromApp) : !this.validateEmail(this.email)) {
      this.notificationService.error('Please have a valid email.');
      return false;
    }
    return true;
  }
  submitFeedback() {
    const feedback: Feedback = {
      Title: this.title,
      Description: this.description,
      Email: this.feedbackFromApp ? this.usernameFromApp : this.email,
      Category: this.selectedCategory,
      Type: this.selectedFeedbackType,
      Status: this.newStatus,
      Photos: this.photos,
    };

    if (this.validate()) {
      this.feedbackService.postFeedback(feedback);
      const successMessage = this.isFeedbackTypeProblem()
        ? 'Your feedback has been submitted and emailed to 2255@orsted.com to create an incident ticket.'
        : 'Your feedback has been submitted.';
      this.notificationService.success(successMessage);

      if (!this.feedbackFromApp) {
        this.router.navigate([`/information/list`]);
      } else {
        this.title = '';
        this.description = '';
        this.photos = [];
        this.tempPhotos = [];
      }
    }
  }

  validateEmail(email) {
    const re =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  handleFile(event) {
    const fileInput = event.target as HTMLInputElement;
    const { files } = fileInput;
    this.photos = [];
    const validFileType = ['image/jpeg', 'image/png'];
    const invalidFiles: File[] = [];

    for (const file of files) {
      if (validFileType.includes(file.type)) {
        this.photos.push(file);
      } else {
        invalidFiles.push(file);
      }
    }

    if (invalidFiles.length > 0) {
      this.notificationService.error(`${invalidFiles.map(file => file.name).join(', ')} is invalid file format.`);
    }

    this.addTempPhotos();
  }

  onCancel() {
    this._location.back();
  }

  private addTempPhotos(): void {
    this.tempPhotos = [];
    this.photos.forEach(photo => {
      const reader = new FileReader();
      reader.readAsDataURL(photo);
      reader.onload = _event => {
        this.tempPhotos.push({
          big: reader.result,
          medium: reader.result,
          small: reader.result,
        });
      };
    });
  }
}
