import { CommonModule } from '@angular/common';
import { Component, ContentChild, ElementRef, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-overview-card',
  templateUrl: './overview-card.component.html',
  styleUrls: ['./overview-card.component.scss'],
  imports: [CommonModule],
})
export class OverviewCardComponent {
  @ContentChild('overviewCardHeader') headerTemplate: ElementRef<HTMLElement>;
  @ContentChild('overviewCardHeaderTitle') headerTitleTemplate: ElementRef<HTMLElement>;
  @ContentChild('overviewCardHeaderOption') headerOptionTemplate: ElementRef<HTMLElement>;

  @Input() headerTitle: string;

  constructor() {}
}
