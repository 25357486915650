import { NgModule } from '@angular/core';

import { AlertComponent } from './alert/alert.component';
import { BadgeComponent } from './badge/badge.component';
import { ButtonComponent } from './button/button.component';
import { OverviewCardComponent } from './overview-card/overview-card.component';
import { TextInputComponent } from './text-input/text-input.component';

@NgModule({
  declarations: [],
  imports: [ButtonComponent, BadgeComponent, AlertComponent, OverviewCardComponent, TextInputComponent],
  exports: [ButtonComponent, BadgeComponent, AlertComponent, OverviewCardComponent, TextInputComponent],
})
export class UiModule {}
