import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { v4 as uuid } from 'uuid';

import { CreateMeasurement, MeasurementPayloadModel, UpdateMeasurement } from '../measurements.action';

@Component({
  selector: 'app-measurement-detail-dialog',
  templateUrl: './measurement-detail-dialog.component.html',
  styleUrls: ['./measurement-detail-dialog.component.scss'],
})
export class MeasurementDetailDialogComponent implements OnInit {
  formMeasurementMaster: FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<MeasurementDetailDialogComponent>,
    private builder: FormBuilder,
    private store: Store
  ) {
    this.formMeasurementMaster = this.builder.group({
      shorthand: [null, [Validators.required, Validators.pattern(/^[A-Z0-9_&/]{2,}$/)]],
      name: [null, Validators.required],
      additionalInfo: [null, null],
      unit: [null, null],
    });
  }

  ngOnInit(): void {
    this.load();
  }

  load(): void {
    if (this.data !== undefined && this.data.measurement !== undefined) {
      this.formMeasurementMaster.patchValue({
        shorthand: this.data.measurement.shorthand,
        name: this.data.measurement.name,
        additionalInfo: this.data.measurement.additionalInformation,
        unit: this.data.measurement.unit,
      });
      if (this.data.action === 'update') {
        this.formMeasurementMaster.controls.shorthand.disable();
      }
    } else {
      this.reset();
    }
  }

  reset(): void {
    this.formMeasurementMaster.reset();
  }

  submit(): void {
    this.formMeasurementMaster.controls.name.markAsTouched();
    this.formMeasurementMaster.controls.shorthand.markAsTouched();
    if (this.formMeasurementMaster.valid && this.data !== undefined) {
      const measurement = <MeasurementPayloadModel>{};
      measurement.name = this.formMeasurementMaster.controls.name.value;
      measurement.shorthand = this.formMeasurementMaster.controls.shorthand.value;
      measurement.additionalInformation =
        this.formMeasurementMaster.controls.additionalInfo.value === null ? '' : this.formMeasurementMaster.controls.additionalInfo.value;
      measurement.unit = this.formMeasurementMaster.controls.unit.value === null ? '' : this.formMeasurementMaster.controls.unit.value;
      measurement.type = '';
      if (this.data.action === 'create') {
        measurement.id = uuid();
        this.store.dispatch(new CreateMeasurement(measurement));
      } else if (this.data.action === 'update') {
        measurement.id = this.data.measurement.id;
        this.store.dispatch(new UpdateMeasurement(measurement));
      }
      this.dialogRef.close();
    }
  }
}
