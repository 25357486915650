<div class="icon-container">
  <mat-icon
    class="icon"
    (click)="details()"
    svgIcon="right"
    data-testid="right-button"></mat-icon>
  <ng-container
    *ngIf="
      this.row.checklistStatus === 'Open' && authorizationService.isAuthorized('checklistdetails.action.updatestatus');
      then thenTemplate;
      else elseTemplate
    "></ng-container>
  <ng-template #thenTemplate>
    <mat-icon
      class="icon"
      [matMenuTriggerFor]="menu"
      svgIcon="menu"
      data-testid="menu-button"></mat-icon>
    <mat-menu
      #menu="matMenu"
      class="table-row-menu">
      <div
        (click)="completeChecklist()"
        mat-menu-item>
        <mat-icon
          class="icon"
          svgIcon="check"
          data-testid="check-button"></mat-icon>
        <span>Complete</span>
      </div>
      <div
        (click)="deleteChecklist()"
        mat-menu-item>
        <mat-icon
          class="icon"
          svgIcon="trash"
          data-testid="trash-button"></mat-icon>
        <span>Delete</span>
      </div>
    </mat-menu>
  </ng-template>
  <ng-template #elseTemplate>
    <mat-icon
      class="icon-disabled"
      svgIcon="menu"
      data-testid="menu-button"></mat-icon>
  </ng-template>
</div>
