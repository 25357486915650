import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ColDef, GridApi, GridReadyEvent, ITooltipParams } from 'ag-grid-community';

import Util from '@app/common/util';
import { AppConfig } from '@config';
import { GalleryDialogComponent } from '@shared/gallery-dialog/gallery-dialog.component';
import { NgxGalleryImage } from '@shared/ngx-gallery';
import { valueClickableLinkCellTemplate } from '@utils/ag-grid';
import { localeDateTime } from '@utils/date';

import { FeedbackPhotoMetadataViewModel, FeedbackViewModel } from '../feedback';
import { FeedbackType } from '../feedback-type';
import { InfoFeedbackService } from '../info-feedback.service';

@Component({
  selector: 'app-feedback-overview',
  templateUrl: './feedback-overview.component.html',
  styleUrls: ['./feedback-overview.component.scss'],
})
export class FeedbackOverviewComponent implements OnInit {
  private gridApi: GridApi;

  public feedbacks: FeedbackViewModel[];
  public domLayout = 'autoHeight';
  public popupParent;
  public defaultColDef: ColDef = {
    filter: true,
    sortable: true,
    resizable: false,
    menuTabs: ['filterMenuTab', 'columnsMenuTab'],
    enableRowGroup: true,
    width: 100,
  };
  public autoGroupColumnDef: ColDef = { minWidth: 200 };
  public columnDefs = [
    {
      headerName: 'TITLE',
      field: 'title',
      tooltipField: 'title',
      width: 200,
      suppressSizeToFit: false,
    },
    {
      headerName: 'DESCRIPTION',
      field: 'description',
      tooltipField: 'description',
      width: 200,
      suppressSizeToFit: false,
    },
    {
      headerName: 'EMAIL',
      headerTooltip: 'Email details of the proposal contact person',
      field: 'email',
      tooltipField: 'email',
      width: 200,
      suppressSizeToFit: false,
    },
    {
      headerName: 'DATE',
      headerTooltip: 'Feedback creation date',
      field: 'created',
      tooltipField: 'createdString',
      width: 110,
      suppressSizeToFit: false,
      valueFormatter: data => {
        return localeDateTime(data.value);
      },
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: Util.gridDateComparator,
      },
    },
    {
      headerName: 'CATEGORY',
      headerTooltip: 'The DIT interface that the proposal relates to',
      field: 'category',
      tooltipField: 'category',
      width: 130,
      suppressSizeToFit: false,
    },
    {
      headerName: 'TYPE',
      field: 'type',
      tooltipValueGetter: (params: ITooltipParams) => this.feedbackTypeGetter(params.value),
      width: 70,
      suppressSizeToFit: false,
      valueGetter: params => this.feedbackTypeGetter(params.data?.type),
    },
    {
      headerName: 'STATUS',
      field: 'status',
      tooltipField: 'status',
      width: 70,
      suppressSizeToFit: false,
    },
    {
      headerName: 'PICTURES',
      headerTooltip: 'Pictures to support the proposal',
      field: 'totalFeedbackPhotos',
      width: 70,
      suppressSizeToFit: false,
      enableRowGroup: false,
      onCellClicked: param => {
        this.showPictures(param.data);
      },
      cellRenderer: params => valueClickableLinkCellTemplate(params.value),
    },
    {
      headerName: 'VOTES',
      headerTooltip: 'Number of votes from other users',
      field: 'totalFeedbackVotes',
      tooltipValueGetter: this.votesGetter,
      width: 70,
      suppressSizeToFit: false,
      valueGetter: this.votesGetter,
    },
    {
      headerName: 'ACTION',
      headerTooltip: 'Vote or remove vote to get development proposals prioritized',
      field: 'isUserVoted',
      tooltipValueGetter: this.actionGetter,
      width: 100,
      suppressSizeToFit: false,
      enableRowGroup: false,
      valueGetter: this.actionGetter,
      getQuickFilterText: this.actionGetter,
      cellRenderer: params => {
        if (!params.node.group) {
          if (this.isProposalType(params.node.data)) {
            return `<span class='clickable-link'>${params.value}</span>`;
          }
        }
      },
      onCellClicked: params => {
        if (!params.node.group) {
          if (this.isProposalType(params.node.data)) {
            this.toggleVote(params.data);
          }
        }
      },
    },
  ];

  constructor(
    private feedbackService: InfoFeedbackService,
    private appConfig: AppConfig,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit() {
    this.popupParent = document.querySelector('body');
  }
  onGridReady($event: GridReadyEvent) {
    this.gridApi = $event.api;
    this.getFeedbacks();
    this.gridApi.sizeColumnsToFit();
  }

  onGridSizeChanged() {
    this.gridApi.sizeColumnsToFit();
  }

  onColumnVisible() {
    this.gridApi.sizeColumnsToFit();
  }

  searchItem($param): void {
    this.gridApi.setGridOption('quickFilterText', $param);
  }

  addFeedback(): void {
    this.router.navigate([`/information/addFeedback`]);
  }

  private getFeedbacks() {
    this.feedbackService.getFeedback().subscribe(response => {
      this.feedbacks = response;
      this.feedbacks.forEach(feedback => {
        feedback.createdString = localeDateTime(feedback.created);
      });
      this.getUserVotes();
      this.gridApi.refreshCells();
    });
  }

  private getUserVotes() {
    if (this.feedbacks && this.feedbacks.length > 0) {
      this.feedbackService.getUserVotes().subscribe(feedbackVotes => {
        feedbackVotes.forEach(feedbackVote => {
          this.feedbacks.find(f => f.id === feedbackVote.feedbackId).isUserVoted = true;
        });
        this.gridApi.refreshCells();
      });
    }
  }

  private showPictures($data: FeedbackViewModel) {
    if ($data.feedbackPhotoMetadata !== undefined && $data.feedbackPhotoMetadata.length > 0) {
      const feedbackPhotos: FeedbackPhotoMetadataViewModel[] = $data.feedbackPhotoMetadata as FeedbackPhotoMetadataViewModel[];
      const imageUrls: NgxGalleryImage[] = [];
      feedbackPhotos.forEach(feedbackPhoto => {
        imageUrls.push({
          big: this.appConfig.storageUrl + feedbackPhoto.path,
          medium: this.appConfig.storageUrl + feedbackPhoto.path,
          small: this.appConfig.storageUrl + feedbackPhoto.path,
          description: 'Timestamp: ' + localeDateTime(feedbackPhoto.clientCreationTimestamp),
        });
      });

      this.dialog.open(GalleryDialogComponent, {
        data: {
          title: `Pictures: ${$data.title}`,
          observationData: imageUrls,
        },
      });
    }
  }

  private toggleVote(data: FeedbackViewModel) {
    if (data.isUserVoted) {
      this.feedbackService.removeVote(data.id).subscribe(() => this.getFeedbacks());
    } else {
      this.feedbackService.addVote(data.id).subscribe(() => this.getFeedbacks());
    }
  }

  private actionGetter(params): string {
    if (!params.node.group) {
      if (params.context.isProposalType(params.node.data)) {
        if (params.data.isUserVoted) {
          return 'Remove Vote';
        } else {
          return 'Vote';
        }
      } else {
        return '';
      }
    }
  }

  private votesGetter(params): string {
    if (!params.node.group) {
      if (params.context.isProposalType(params.node.data)) {
        return `${params.node.data.totalFeedbackVotes}`;
      }
    }
  }

  private isProposalType(data: FeedbackViewModel): boolean {
    return FeedbackType[data.type].toString() === FeedbackType.Proposal.toString();
  }

  private feedbackTypeGetter(value: string) {
    if (value === FeedbackType[FeedbackType.Problem]) {
      return 'Issue';
    } else {
      return value;
    }
  }
}
