import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SkeletonModule } from 'primeng/skeleton';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-detail-list',
  templateUrl: './detail-list.component.html',
  standalone: true,
  imports: [CommonModule, SkeletonModule],
})
export class DetailListComponent<TItem = { name: string }> {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input({ alias: 'items', required: true }) items$: Observable<TItem[]>;
  expanded: boolean = false;

  onExpand() {
    this.expanded = true;
  }

  onCollapse() {
    this.expanded = false;
  }
}
