<h2
  mat-dialog-title
  class="dialog-title">
  {{ data.title }}
</h2>
<mat-dialog-content class="dialog-content">
  <div class="export-options">
    <ul>
      <li>Status</li>
      <li>
        <input
          type="checkbox"
          [(ngModel)]="includeGradeOk" />
        OK
      </li>
      <li>
        <input
          type="checkbox"
          [(ngModel)]="includeGradeNotOk"
          (ngModelChange)="nokChanged($event)" />
        NOK
      </li>
      <li *ngIf="this.data.exporterType !== ExporterType.GeneralObservation">
        <input
          type="checkbox"
          [(ngModel)]="includeGradeNa" />
        N/A
      </li>
      <li *ngIf="this.data.exporterType !== ExporterType.GeneralObservation">
        <input
          type="checkbox"
          [(ngModel)]="includeGradeNotInspected" />
        Not Inspected
      </li>
    </ul>
  </div>
  <div
    class="export-options"
    [ngClass]="!includeGradeNotOk ? 'disabled' : ''">
    <ul>
      <li>Severity</li>
      <li *ngFor="let severity of includeSeverity | keyvalue : returnZero">
        <input
          type="checkbox"
          [(ngModel)]="includeSeverity[severity.key].value"
          [disabled]="!includeGradeNotOk" />
        {{ includeSeverity[severity.key].label }}
      </li>
    </ul>
  </div>
  <div class="export-options">
    <ul>
      <li>Picture Links</li>
      <li>
        <input
          type="radio"
          name="imageOption"
          [value]="ImageOption.Include"
          [(ngModel)]="imageOption" />
        Include
      </li>
      <li>
        <input
          type="radio"
          name="imageOption"
          [value]="ImageOption.Exclude"
          [(ngModel)]="imageOption" />
        Exclude
      </li>
    </ul>
  </div>
  <div class="export-options right">
    <ul>
      <li>Re-Inspections</li>
      <li>
        <input
          type="radio"
          value="include"
          [(ngModel)]="reinspectionOption" />
        Include
      </li>
      <li>
        <input
          type="radio"
          value="exclude"
          [(ngModel)]="reinspectionOption" />
        Exclude
      </li>
      <li>
        <input
          type="radio"
          value="merge"
          [(ngModel)]="reinspectionOption" />
        Merge
      </li>
    </ul>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button
    dialog-button
    (click)="submitOptions(ExportType.Excel)">
    {{ excelButtonText }}
  </button>
  <button
    *ngIf="this.data.exporterType !== ExporterType.Checklist"
    dialog-button
    (click)="submitOptions(ExportType.Pdf)">
    {{ pdfButtonText }}
  </button>
  <button
    dialog-button
    mat-dialog-close
    class="secondary-button">
    {{ cancelButtonText }}
  </button>
</mat-dialog-actions>
