import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

import { StorageService } from './storage.service';

export const AuthGuard: CanActivateFn = async () => {
  const msalService = inject(MsalService);
  const storageService = inject(StorageService);

  await msalService.instance.initialize();
  if (msalService.instance.getAllAccounts().length > 0) {
    return true;
  } else {
    if (window.location.pathname.indexOf('gallery') !== -1) {
      storageService.saveData('galleryUrl', window.location.href.slice(window.location.href.indexOf('gallery') - 1));
    } else if (window.location.pathname.indexOf('download') !== -1) {
      storageService.saveData('downloadUrl', window.location.href.slice(window.location.href.indexOf('download') - 1));
    }
    msalService.instance.loginRedirect();
  }
  return false;
};
