@if (items$ | async; as items) {
<ul class="list-group">
  @for (item of items | slice : 0 : 3; track item) {
  <li class="list-group-item">
    {{ item.name }}
  </li>
  } @if (items.length > 3 && !expanded) {
  <li class="list-group-item">
    <a
      href="javascript:void(0)"
      (click)="onExpand()">
      Show All
    </a>
  </li>
  } @if (expanded) { @for (expandedItem of items | slice : 3; track expandedItem) {
  <li class="list-group-item">
    {{ expandedItem.name }}
  </li>
  }
  <li class="list-group-item">
    <a
      href="javascript:void(0)"
      (click)="onCollapse()">
      Show Less
    </a>
  </li>
  }
  <ng-content select="[detail-list-item]"></ng-content>
</ul>
} @else {
<div class="d-flex align-items-center border rounded bg-body gap-2 px-2 py-1">
  <p-skeleton
    class="col"
    width="100%"
    height="156px" />
</div>
}
