import { Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import type { OTextInput } from '@oxygen/components/lib/text-input';
import '@oxygen/components/lib/text-input';

@Component({
  standalone: true,
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextInputComponent),
      multi: true,
    },
  ],
})
export class TextInputComponent implements ControlValueAccessor {
  @ViewChild('textInput') oInput!: ElementRef<OTextInput>;

  @Input() type = 'text';
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() label = '';
  @Input() placeholder = '';
  @Input() description = '';

  constructor() {}

  private onChange: (value: string) => void = () => {};
  private onTouched: () => void = () => {};

  value: string = '';

  onOChange(event: Event) {
    const el = event.target as OTextInput;
    this.value = el.value;
    this.onChange(this.value);
  }

  onOInput(event: Event) {
    const el = event.target as OTextInput;
    this.value = el.value;
    this.onChange(this.value);
  }

  writeValue(value: string): void {
    this.value = value;
    if (this.oInput?.nativeElement) {
      this.oInput.nativeElement.value = value;
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
