import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Project } from '@app/models/entity';
import { AppConfig } from '@config';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  constructor(private http: HttpClient, private appConfig: AppConfig) {}

  getProjects(): Observable<Project[]> {
    const url = `${this.appConfig.apiUrl}/admin/v1/Projects`;
    return this.http.get<Project[]>(url);
  }

  getAPIProjects(): Observable<any[]> {
    const url = `${this.appConfig.apiUrl}/export/v1/Projects`;
    return this.http.get<any[]>(url);
  }

  getAPIProjectById(): Observable<any[]> {
    const url = `${this.appConfig.apiUrl}/export/v1/Projects/E9E60BA8-3BAD-439F-D7E1-08D6975560DA`;
    return this.http.get<any[]>(url);
  }

  getAPIAdhocInspections(): Observable<any[]> {
    const url = `${this.appConfig.apiUrl}/export/v1/Projects/E9E60BA8-3BAD-439F-D7E1-08D6975560DA/adhocInspections`;
    return this.http.get<any[]>(url);
  }

  getAPIAdhocInspectionById(): Observable<any[]> {
    const url = `${this.appConfig.apiUrl}/export/v1/Projects/E9E60BA8-3BAD-439F-D7E1-08D6975560DA/adhocInspections/762BAD78-6C3B-4934-8C92-022ABB61EB37`;
    return this.http.get<any[]>(url);
  }

  getAPIAdhocInspectionAttachmentById(): Observable<any[]> {
    const url = `${this.appConfig.apiUrl}/export/v1/Projects/E9E60BA8-3BAD-439F-D7E1-08D6975560DA/adhocInspections/4E52002A-4FE5-4C2F-A8FF-FA4ED7F460E1/attachments`;
    return this.http.get<any[]>(url);
  }

  getAPIRegularInspections(): Observable<any[]> {
    const url = `${this.appConfig.apiUrl}/export/v1/Projects/E9E60BA8-3BAD-439F-D7E1-08D6975560DA/regularInspections`;
    return this.http.get<any[]>(url);
  }

  getAPIRegularInspectionById(): Observable<any[]> {
    const url = `${this.appConfig.apiUrl}/export/v1/Projects/E9E60BA8-3BAD-439F-D7E1-08D6975560DA/regularInspections/D04334C5-3122-4377-8047-0E64199E24E8`;
    return this.http.get<any[]>(url);
  }

  getAPIRegularInspectionAttachmentById(): Observable<any[]> {
    const url = `${this.appConfig.apiUrl}/export/v1/Projects/E9E60BA8-3BAD-439F-D7E1-08D6975560DA/regularInspections/D04334C5-3122-4377-8047-0E64199E24E8/attachments`;
    return this.http.get<any[]>(url);
  }

  getAPIGeneralInspections(): Observable<any[]> {
    const url = `${this.appConfig.apiUrl}/export/v1/Projects/E9E60BA8-3BAD-439F-D7E1-08D6975560DA/generalInspections`;
    return this.http.get<any[]>(url);
  }

  getAPIGeneralInspectionById(): Observable<any[]> {
    const url = `${this.appConfig.apiUrl}/export/v1/Projects/E9E60BA8-3BAD-439F-D7E1-08D6975560DA/generalInspections/9F744E85-2809-4AFF-841B-AA23616FDBE1`;
    return this.http.get<any[]>(url);
  }

  getAPIGeneralInspectionAttachmentById(): Observable<any[]> {
    const url = `${this.appConfig.apiUrl}/export/v1/Projects/E9E60BA8-3BAD-439F-D7E1-08D6975560DA/generalInspections/9F744E85-2809-4AFF-841B-AA23616FDBE1/attachments`;
    return this.http.get<any[]>(url);
  }
}
