<div class="ngx-gallery-thumbnails-wrapper ngx-gallery-thumbnail-size-{{ size }}">
  <div
    class="ngx-gallery-thumbnails"
    [style.transform]="'translateX(' + thumbnailsLeft + ')'"
    [style.marginLeft]="thumbnailsMarginLeft">
    <a
      [href]="hasLink(i) ? links[i] : '#'"
      [target]="linkTarget"
      class="ngx-gallery-thumbnail"
      *ngFor="let image of getImages(); let i = index"
      [style.background-image]="getSafeUrl(image)"
      (click)="handleClick($event, i)"
      [style.width]="getThumbnailWidth()"
      [style.height]="getThumbnailHeight()"
      [style.left]="getThumbnailLeft(i)"
      [style.top]="getThumbnailTop(i)"
      [ngClass]="{ 'ngx-gallery-active': i === selectedIndex, 'ngx-gallery-clickable': clickable }"
      [attr.aria-label]="labels[i]">
      <div class="ngx-gallery-icons-wrapper">
        <ngx-gallery-action
          *ngFor="let action of actions"
          [icon]="action.icon"
          [disabled]="action.disabled"
          [titleText]="action.titleText"
          (onClick)="action.onClick($event, i)"></ngx-gallery-action>
      </div>
      <div
        class="ngx-gallery-remaining-count-overlay"
        *ngIf="remainingCount && remainingCountValue && i === rows * columns - 1">
        <span class="ngx-gallery-remaining-count">+{{ remainingCountValue }}</span>
      </div>
    </a>
  </div>
</div>
<ngx-gallery-arrows
  *ngIf="canShowArrows()"
  (onPrevClick)="moveLeft()"
  (onNextClick)="moveRight()"
  [prevDisabled]="!canMoveLeft()"
  [nextDisabled]="!canMoveRight()"
  [arrowPrevIcon]="arrowPrevIcon"
  [arrowNextIcon]="arrowNextIcon"></ngx-gallery-arrows>
