<mat-toolbar
  mat-toolbar
  class="mat-elevation-z3"
  *ngIf="authorizationService.authenticated">
  <div class="nav-home">
    <a [routerLink]="['/assignment']">
      <mat-icon nav-icon>home</mat-icon>
    </a>
  </div>
  <div class="nav-logo">
    <img
      src="assets/images/dit-logo_64x64.png"
      height="42"
      alt="Orsted Logo" />
    <span class="nav-logo-title">Digital Inspection Tool</span>
  </div>
  <nav class="nav lighten-4">
    <a
      class="nav-link navigation-border white-text"
      [routerLink]="['/assignment']"
      routerLinkActive="navigation-border-selected">
      Projects
    </a>
    <a
      *ngIf="authorizationService.isAuthorized('handovers')"
      class="nav-link navigation-border white-text"
      [routerLink]="['/handoverassignment']"
      routerLinkActive="navigation-border-selected">
      Handover
    </a>
    <a
      *ngIf="authorizationService.isAuthorized('admins')"
      class="nav-link navigation-border white-text"
      [routerLink]="['/master']"
      routerLinkActive="navigation-border-selected">
      Master
    </a>
    <a
      *ngIf="authorizationService.isAuthorized('feedback')"
      class="nav-link navigation-border white-text"
      [routerLink]="['/information/feedback']"
      routerLinkActive="navigation-border-selected">
      Feedback
    </a>
    <a
      class="nav-link navigation-border white-text"
      [routerLink]="['/information/list']"
      routerLinkActive="navigation-border-selected">
      Information
    </a>
    <a
      class="nav-link navigation-border white-text"
      [routerLink]="['/information/faq']"
      routerLinkActive="navigation-border-selected">
      FAQs
    </a>
  </nav>

  <div class="nav-profile">
    <mat-icon nav-profile-icon>person</mat-icon>
    <label class="name-initials">{{ userName }}</label>
  </div>
  <div class="nav-settings">
    <div
      class="nav-settings-button"
      mat-button
      [matMenuTriggerFor]="settingMenu">
      <mat-icon nav-icon>settings</mat-icon>
    </div>
    <mat-menu #settingMenu="matMenu">
      <div
        *ngIf="authorizationService.isAuthorized('addassignment')"
        mat-menu-item>
        <a [routerLink]="['/userassignment']">Add Assignment</a>
      </div>
      <mat-divider *ngIf="authorizationService.isAuthorized('addassignment')"></mat-divider>
      <div mat-menu-item>
        <a (click)="logout()">Log out</a>
      </div>
    </mat-menu>
  </div>
</mat-toolbar>
