import { Injectable } from '@angular/core';

import { environment } from '@env';

@Injectable({
  providedIn: 'root',
})
export class AppConfig {
  public apiUrl = environment.apiUrl;
  public authConfig = environment.auth;
  public storageUrl = environment.storageUrl;
}
