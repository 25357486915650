import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-nav-bar',
  templateUrl: './info-nav-bar.component.html',
  styleUrls: ['./info-nav-bar.component.scss'],
})
export class InfoNavBarComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
