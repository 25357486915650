import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { NotificationService } from '@app/services/notification.service';

import {
  CreateMeasurement,
  DeleteMeasurement,
  GetMeasurements,
  MeasurementPayloadModel,
  SelectMeasurement,
  UpdateMeasurement,
} from './measurements.action';
import { MeasurementService } from './measurements.service';

export class MeasurementStateModel {
  measurements: MeasurementPayloadModel[] | null;
  selectedMeasurement: MeasurementPayloadModel;
}

@State<MeasurementStateModel>({
  name: 'masterMeasurements',
  defaults: {
    measurements: null,
    selectedMeasurement: null,
  },
})
@Injectable()
export class MeasurementState {
  constructor(private measurementService: MeasurementService, private notificationService: NotificationService) {}

  @Selector()
  static getMeasurements(state: MeasurementStateModel) {
    return state.measurements;
  }

  @Selector()
  static getSelectedMeasurement(state: MeasurementStateModel) {
    return state.selectedMeasurement;
  }

  @Action(GetMeasurements)
  getMeasurementsFromService({ patchState }: StateContext<MeasurementStateModel>) {
    return this.measurementService.getAllMeasurements().pipe(
      tap(result => {
        patchState({ measurements: result });
      })
    );
  }

  @Action(SelectMeasurement)
  selectMeasurementFromState({ getState, setState }: StateContext<MeasurementStateModel>, { payload }: SelectMeasurement) {
    const state = getState();
    setState({
      ...state,
      selectedMeasurement: state.measurements.find(({ id }) => id === payload.measurementId),
    });
  }

  @Action(CreateMeasurement)
  createMeasurementFromService({ getState, setState }: StateContext<MeasurementStateModel>, { payload }: CreateMeasurement) {
    const state = getState();
    setState({
      ...state,
      measurements: state.measurements.concat(payload),
    });
    return this.measurementService.createMeasurement(payload).pipe(
      tap({
        next: result => {
          if (result === false) {
            setState({
              ...state,
              measurements: state.measurements.filter(({ id }) => id !== payload.id),
            });
          } else {
            this.notificationService.success('Measurement is successfully added.');
          }
        },
        error: () => {
          setState({
            ...state,
            measurements: state.measurements.filter(({ id }) => id !== payload.id),
          });
          this.notificationService.error('Failed to create a new measurement.');
        },
      })
    );
  }

  @Action(DeleteMeasurement)
  deleteMeasurementFromService({ getState, setState }: StateContext<MeasurementStateModel>, { payload }: DeleteMeasurement) {
    const state = getState();
    const oldMeasurement = state.measurements.find(({ id }) => id === payload.measurementId);
    const newMeasurements = state.measurements.filter(({ id }) => id !== payload.measurementId);
    setState({
      ...state,
      measurements: [...newMeasurements],
    });
    return this.measurementService.deleteMeasurement(payload.measurementId).pipe(
      tap({
        next: result => {
          if (result === false) {
            setState({
              ...state,
              measurements: newMeasurements.concat(oldMeasurement),
            });
          } else {
            this.notificationService.success('Measurement is successfully deleted.');
          }
        },
        error: () => {
          setState({
            ...state,
            measurements: newMeasurements.concat(oldMeasurement),
          });
          this.notificationService.error('Failed to delete a measurement.');
        },
      })
    );
  }

  @Action(UpdateMeasurement)
  updateMeasurementFromService({ getState, setState }: StateContext<MeasurementStateModel>, { payload }: UpdateMeasurement) {
    const state = getState();
    const newMeasurements = state.measurements;
    const oldMeasurement = state.measurements.find(({ id }) => id === payload.id);
    const index = state.measurements.indexOf(oldMeasurement);
    newMeasurements[index] = payload;
    setState({
      ...state,
      measurements: [...newMeasurements],
    });
    return this.measurementService.updateMeasurement(payload).pipe(
      tap({
        next: result => {
          if (result === false) {
            newMeasurements[index] = oldMeasurement;
            setState({
              ...state,
              measurements: [...newMeasurements],
            });
            this.notificationService.error('Failed to update existing measurement.');
          } else {
            this.notificationService.success('Measurement is successfully updated.');
          }
        },
        error: () => {
          newMeasurements[index] = oldMeasurement;
          setState({
            ...state,
            measurements: [...newMeasurements],
          });
          this.notificationService.error('Failed to update existing measurement.');
        },
      })
    );
  }
}
