import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

import Util from '@app/common/util';
import {
  InspectionGrade,
  InspectionMeasurementValues,
  InspectionMediaMetadataCopyInputModel,
  InspectionSeverity,
  InspectionType,
  NewUnionRegularAdHocInspectionInputModel,
} from '@app/models/checklist';
import { ExportOptions } from '@app/models/export-options';
import { AppConfig } from '@config';

@Injectable({
  providedIn: 'root',
})
export class ChecklistDetailService {
  constructor(private http: HttpClient, private appConfig: AppConfig, private msalService: MsalService) {}

  get userName(): string {
    let activeAccount = this.msalService.instance.getActiveAccount();
    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      const accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
      activeAccount = this.msalService.instance.getActiveAccount();
    }
    return activeAccount.username;
  }

  emailChecklistDetailExportFile(checklistId: string, exportOption: ExportOptions) {
    const url = `${this.appConfig.apiUrl}/admin/v1/Checklists/${checklistId}/export`;
    return this.http.post(url, exportOption);
  }

  emailChecklistsExportFile(checklistIds: string[], exportOptions: ExportOptions) {
    const url = `${this.appConfig.apiUrl}/admin/v1/Checklists/export`;
    const data = {
      ChecklistIds: checklistIds,
      ExportOptions: exportOptions,
    };
    return this.http.post(url, data);
  }

  createRegularInspection(
    checklistTemplateGroupId: string,
    checklistTemplateItemId: string,
    checklistId: string,
    inspectionId: string,
    name: string,
    inspectionStatus: InspectionGrade,
    inspectionSeverity: InspectionSeverity,
    comment: string,
    itemCategoryId: string,
    inspectionMeasurementValues: InspectionMeasurementValues[][],
    inspectionPhotoMetadataCopy?: InspectionMediaMetadataCopyInputModel,
    inspectionDrawingMetadataCopy?: InspectionMediaMetadataCopyInputModel
  ) {
    const url = `${this.appConfig.apiUrl}/admin/v1/Checklists/${checklistId}/inspections/${inspectionId}`;

    const data: NewUnionRegularAdHocInspectionInputModel = {
      inspectionType: InspectionType.Regular,
      name,
      checklistTemplateGroupId,
      checklistTemplateItemId,
      clientCreationTimestamp: new Date(),
      inspectionGrade: inspectionStatus,
      inspectionSeverity,
      comment,
      itemCategoryId,
      createdByUser: this.userName,
      inspectionMeasurementValues,
      inspectionPhotoMetadataCopy,
      inspectionDrawingMetadataCopy,
    };
    return this.http.post<NewUnionRegularAdHocInspectionInputModel>(url, data);
  }

  createSubRegularInspection(
    checklistTemplateGroupId: string,
    checklistTemplateItemId: string,
    checklistId: string,
    inspectionId: string,
    name: string,
    inspectionStatus: InspectionGrade,
    inspectionSeverity: InspectionSeverity,
    comment: string,
    subChecklistItemReference: string,
    itemCategoryId: string,
    inspectionMeasurementValues: InspectionMeasurementValues[][],
    inspectionPhotoMetadataCopy?: InspectionMediaMetadataCopyInputModel,
    inspectionDrawingMetadataCopy?: InspectionMediaMetadataCopyInputModel
  ) {
    const url = `${this.appConfig.apiUrl}/admin/v1/Checklists/${checklistId}/inspections/${inspectionId}`;

    const data: NewUnionRegularAdHocInspectionInputModel = {
      inspectionType: InspectionType.SubRegular,
      name: name,
      checklistTemplateGroupId: checklistTemplateGroupId,
      checklistTemplateItemId: checklistTemplateItemId,
      clientCreationTimestamp: new Date(),
      inspectionGrade: inspectionStatus,
      inspectionSeverity: inspectionSeverity,
      comment: comment,
      createdByUser: this.userName,
      subChecklistItemReference: subChecklistItemReference,
      itemCategoryId,
      inspectionMeasurementValues,
      inspectionPhotoMetadataCopy,
      inspectionDrawingMetadataCopy,
    };

    return this.http.post<NewUnionRegularAdHocInspectionInputModel>(url, data);
  }

  createAdhocInspection(
    checklistId: string,
    inspectionId: string,
    name: string,
    adHocItemReference: string,
    inspectionStatus: InspectionGrade,
    inspectionSeverity: InspectionSeverity,
    comment: string,
    itemCategoryId: string,
    inspectionPhotoMetadataCopy?: InspectionMediaMetadataCopyInputModel
  ) {
    const url = `${this.appConfig.apiUrl}/admin/v1/Checklists/${checklistId}/inspections/${inspectionId}`;

    const data: NewUnionRegularAdHocInspectionInputModel = {
      inspectionType: InspectionType.AdHoc,
      name,
      adhocItemReference: adHocItemReference,
      clientCreationTimestamp: new Date(),
      inspectionGrade: inspectionStatus,
      inspectionSeverity,
      comment,
      inspectionPhotoMetadataCopy,
      createdByUser: this.userName,
      itemCategoryId,
    };

    return this.http.post<NewUnionRegularAdHocInspectionInputModel>(url, data);
  }

  UploadRegularInspectionPhoto(
    checklistId: string,
    checklistTemplateGroupId: string,
    checklistTemplateItemId: string,
    inspectionId: string,
    metadataId: string,
    photo: File
  ) {
    const url = `${this.appConfig.apiUrl}/admin/v1/Checklists/${checklistId}/groups/${checklistTemplateGroupId}/items/${checklistTemplateItemId}/regularInspections/${inspectionId}/photos/${metadataId}`;
    return this.http.post(url, photo, Util.getHttpFileUploadOptions());
  }

  CopyRegularInspectionPhotos(
    checklistId: string,
    checklistTemplateGroupId: string,
    checklistTemplateItemId: string,
    inspectionId: string,
    sourceInspectionId: string,
    sourceMetadataIds: string[]
  ) {
    const url = `${this.appConfig.apiUrl}/admin/v1/Checklists/${checklistId}/groups/${checklistTemplateGroupId}/items/${checklistTemplateItemId}/regularInspections/${inspectionId}/photos/copy/${sourceInspectionId}`;

    return this.http.post(url, sourceMetadataIds);
  }

  UploadRegularInspectionDrawing(
    checklistId: string,
    checklistTemplateGroupId: string,
    checklistTemplateItemId: string,
    checklistTemplateItemDrawingMetadataId: string,
    inspectionId: string,
    metadataId: string,
    photo: File
  ) {
    const url = `${this.appConfig.apiUrl}/admin/v1/Checklists/${checklistId}/groups/${checklistTemplateGroupId}/items/${checklistTemplateItemId}/templateItemDrawing/${checklistTemplateItemDrawingMetadataId}/regularInspections/${inspectionId}/drawings/${metadataId}`;

    return this.http.post(url, photo, Util.getHttpFileUploadOptions());
  }

  CopyRegularInspectionDrawings(
    checklistId: string,
    checklistTemplateGroupId: string,
    checklistTemplateItemId: string,
    inspectionId: string,
    sourceInspectionId: string,
    sourceMetadataIds: string[]
  ) {
    const url = `${this.appConfig.apiUrl}/admin/v1/Checklists/${checklistId}/groups/${checklistTemplateGroupId}/items/${checklistTemplateItemId}/regularInspections/${inspectionId}/drawings/copy/${sourceInspectionId}`;
    return this.http.post(url, sourceMetadataIds);
  }

  UploadSubRegularInspectionPhoto(
    checklistId: string,
    checklistTemplateGroupId: string,
    checklistTemplateItemId: string,
    inspectionId: string,
    metadataId: string,
    photo: File
  ) {
    const url = `${this.appConfig.apiUrl}/admin/v1/Checklists/${checklistId}/groups/${checklistTemplateGroupId}/items/${checklistTemplateItemId}/subRegularInspections/${inspectionId}/photos/${metadataId}`;
    return this.http.post(url, photo, Util.getHttpFileUploadOptions());
  }

  CopySubRegularInspectionPhotos(
    checklistId: string,
    checklistTemplateGroupId: string,
    checklistTemplateItemId: string,
    inspectionId: string,
    sourceInspectionId: string,
    sourceMetadataIds: string[]
  ) {
    const url = `${this.appConfig.apiUrl}/admin/v1/Checklists/${checklistId}/groups/${checklistTemplateGroupId}/items/${checklistTemplateItemId}/subRegularInspections/${inspectionId}/photos/copy/${sourceInspectionId}`;
    return this.http.post(url, sourceMetadataIds);
  }

  UploadSubRegularInspectionDrawing(
    checklistId: string,
    checklistTemplateGroupId: string,
    checklistTemplateItemId: string,
    checklistTemplateItemDrawingMetadataId: string,
    inspectionId: string,
    metadataId: string,
    photo: File
  ) {
    const url = `${this.appConfig.apiUrl}/admin/v1/Checklists/${checklistId}/group/${checklistTemplateGroupId}/items/${checklistTemplateItemId}/templateItemDrawing/${checklistTemplateItemDrawingMetadataId}/subRegularInspections/${inspectionId}/drawings/${metadataId}`;
    return this.http.post(url, photo, Util.getHttpFileUploadOptions());
  }

  CopySubRegularInspectionDrawings(
    checklistId: string,
    checklistTemplateGroupId: string,
    checklistTemplateItemId: string,
    inspectionId: string,
    sourceInspectionId: string,
    sourceMetadataIds: string[]
  ) {
    const url = `${this.appConfig.apiUrl}/admin/v1/Checklists/${checklistId}/groups/${checklistTemplateGroupId}/items/${checklistTemplateItemId}/subRegularInspections/${inspectionId}/drawings/copy/${sourceInspectionId}`;
    return this.http.post(url, sourceMetadataIds);
  }

  UploadAdhocInspectionPhoto(checklistId: string, inspectionId: string, metadataId: string, photo: File) {
    const url = `${this.appConfig.apiUrl}/admin/v1/Checklists/${checklistId}/adHocInspections/${inspectionId}/photos/${metadataId}`;
    return this.http.post(url, photo, Util.getHttpFileUploadOptions());
  }

  CopyAdhocInspectionPhoto(checklistId: string, inspectionId: string, sourceInspectionId: string, sourceMetadataIds: string[]) {
    const url = `${this.appConfig.apiUrl}/admin/v1/Checklists/${checklistId}/adHocInspections/${inspectionId}/photos/copy/${sourceInspectionId}`;
    return this.http.post(url, sourceMetadataIds);
  }
}
