import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';

import { InfoFaqComponent } from './info-faq/info-faq.component';
import { FeedbackOverviewComponent } from './info-feedback/feedback-overview/feedback-overview.component';
import { InfoFeedbackComponent } from './info-feedback/info-feedback.component';
import { InfoListComponent } from './info-list/info-list.component';
import { InfoNavBarComponent } from './info-nav-bar/info-nav-bar.component';
import { InformationRouteModule } from './information.routing';

@NgModule({
  imports: [InformationRouteModule, SharedModule],
  declarations: [InfoListComponent, InfoFaqComponent, InfoFeedbackComponent, InfoNavBarComponent, FeedbackOverviewComponent],
  exports: [InfoListComponent, InfoFaqComponent, InfoFeedbackComponent],
})
export class InformationModule {}
