import { NgModule } from '@angular/core';

import { DetailEntityComponent } from './detail-entity/detail-entity.component';
import { DetailLabelComponent } from './detail-label/detail-label.component';
import { DetailListComponent } from './detail-list/detail-list.component';

@NgModule({
  declarations: [],
  imports: [DetailEntityComponent, DetailLabelComponent, DetailListComponent],
  exports: [DetailEntityComponent, DetailLabelComponent, DetailListComponent],
  providers: [],
})
export class DetailModule {}
