<ngx-gallery-arrows
  *ngIf="arrows"
  (onPrevClick)="showPrev()"
  (onNextClick)="showNext()"
  [prevDisabled]="!canShowPrev()"
  [nextDisabled]="!canShowNext()"
  [arrowPrevIcon]="arrowPrevIcon"
  [arrowNextIcon]="arrowNextIcon"></ngx-gallery-arrows>
<div class="ngx-gallery-preview-top">
  <div class="ngx-gallery-preview-icons">
    <ngx-gallery-action
      *ngFor="let action of actions"
      [icon]="action.icon"
      [disabled]="action.disabled"
      [titleText]="action.titleText"
      (onClick)="action.onClick($event, index)"></ngx-gallery-action>
    <a
      *ngIf="download && src"
      [href]="src"
      class="ngx-gallery-icon"
      aria-hidden="true"
      download>
      <i class="ngx-gallery-icon-content {{ downloadIcon }}"></i>
    </a>
    <ngx-gallery-action
      *ngIf="zoom"
      [icon]="zoomOutIcon"
      [disabled]="!canZoomOut()"
      (onClick)="zoomOut()"></ngx-gallery-action>
    <ngx-gallery-action
      *ngIf="zoom"
      [icon]="zoomInIcon"
      [disabled]="!canZoomIn()"
      (onClick)="zoomIn()"></ngx-gallery-action>
    <ngx-gallery-action
      *ngIf="rotate"
      [icon]="rotateLeftIcon"
      (onClick)="rotateLeft()"></ngx-gallery-action>
    <ngx-gallery-action
      *ngIf="rotate"
      [icon]="rotateRightIcon"
      (onClick)="rotateRight()"></ngx-gallery-action>
    <ngx-gallery-action
      *ngIf="fullscreen"
      [icon]="'ngx-gallery-fullscreen ' + fullscreenIcon"
      (onClick)="manageFullscreen()"></ngx-gallery-action>
    <ngx-gallery-action
      [icon]="'ngx-gallery-close ' + closeIcon"
      (onClick)="close()"></ngx-gallery-action>
  </div>
</div>
<div
  class="ngx-spinner-wrapper ngx-gallery-center"
  [class.ngx-gallery-active]="showSpinner">
  <i
    class="ngx-gallery-icon ngx-gallery-spinner {{ spinnerIcon }}"
    aria-hidden="true"></i>
</div>
<div
  class="ngx-gallery-preview-wrapper"
  (click)="closeOnClick && close()"
  (mouseup)="mouseUpHandler($event)"
  (mousemove)="mouseMoveHandler($event)"
  (touchend)="mouseUpHandler($event)"
  (touchmove)="mouseMoveHandler($event)">
  <div class="ngx-gallery-preview-img-wrapper">
    <img
      *ngIf="src"
      #previewImage
      class="ngx-gallery-preview-img ngx-gallery-center"
      [src]="src"
      (click)="$event.stopPropagation()"
      (mouseenter)="imageMouseEnter()"
      (mouseleave)="imageMouseLeave()"
      (mousedown)="mouseDownHandler($event)"
      (touchstart)="mouseDownHandler($event)"
      [class.ngx-gallery-active]="!loading"
      [class.animation]="animation"
      [class.ngx-gallery-grab]="canDragOnZoom()"
      [style.transform]="getTransform()"
      [style.left]="positionLeft + 'px'"
      [style.top]="positionTop + 'px'" />
    <ngx-gallery-bullets
      *ngIf="bullets"
      [count]="images.length"
      [active]="index"
      (onChange)="showAtIndex($event)"></ngx-gallery-bullets>
  </div>
  <div
    class="ngx-gallery-preview-text"
    *ngIf="showDescription && description"
    [innerHTML]="description"
    (click)="$event.stopPropagation()"></div>
</div>
