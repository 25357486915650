import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeEnGb from '@angular/common/locales/en-GB';
import { NgModule, NO_ERRORS_SCHEMA, LOCALE_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsModule } from '@ngxs/store';

import { CoreModule } from '@app/core.module';
import { HttpErrorInterceptor, ApiKeyInterceptor } from '@app/interceptors';
import { MsalConfigModule } from '@app/msal-config.module';
import { SVG_ICONS } from '@app/services/svg-icons/svg-icons';
import { SvgIconsService } from '@app/services/svg-icons/svg-icons.service';
import { AppConfig } from '@config';
import { environment } from '@env';
import { SharedModule } from '@shared/shared.module';

import { GalleryModule } from '@features/gallery/gallery.module';
import { InformationModule } from '@features/information/information.module';
import { MasterModule } from '@features/master/master.module';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';

registerLocaleData(localeEnGb);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    GalleryModule,
    FormsModule,
    BrowserAnimationsModule,
    InformationModule,
    MasterModule,
    CoreModule,
    NgxsModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot({ disabled: !environment.production }),
    MsalConfigModule.forRoot(),
  ],
  providers: [
    AppConfig,
    [{ provide: LOCALE_ID, useValue: 'en-GB' }],
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiKeyInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA],
})
export class AppModule {
  constructor(svgIconsService: SvgIconsService) {
    svgIconsService.registerIcons(SVG_ICONS);
  }
}
