<h1 mat-dialog-title>
  <i
    class="fa fa-warning"
    style="color: red"></i>
  <span style="padding-left: 10px">{{ data.title }}</span>
</h1>
<div
  mat-dialog-content
  class="dialog-content">
  <p>{{ data.message }}</p>
</div>
<div
  mat-dialog-actions
  align="end">
  <button
    class="secondary-button"
    dialog-button
    mat-dialog-close>
    Close
  </button>
</div>
