import { InspectionSeverity } from './checklist';

export interface ExportOptions {
  FileType: ExportType;
  PictureOption: ImageOption;
  InspectionGrades: InspectionGrade[];
  InspectionSeverities: InspectionSeverity[];
  reinspectionOption: string;
}

export enum ImageOption {
  Exclude = 'Exclude',
  Include = 'Include',
  LinkOnly = 'LinkOnly',
}

export enum InspectionGrade {
  Ok = 'Ok',
  NotOk = 'Nok',
  Na = 'Na',
  NotInspected = 'NotInspected',
}

export enum ExportType {
  Excel = 'Excel',
  Pdf = 'Pdf',
}

export enum ExporterType {
  Checklists,
  Checklist,
  GeneralObservation,
}
