import { Component, Input } from '@angular/core';

import { Statistic } from './statistic';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
})
export class StatisticsComponent {
  @Input() statistics: Statistic[];
}
