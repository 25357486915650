import { NgModule, isDevMode } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

import { AuthGuard } from '@app/services/authentication/authentication.guard';
import { AuthAdminGuard } from '@app/services/authorization/authorization.admin.guard';
import { DownloadComponent } from '@shared/download/download.component';
import { ExportApiComponent } from '@shared/export-api/export-api.component';
import { HomeComponent } from '@shared/home/home.component';
import { LoginComponent } from '@shared/login/login.component';
import { PageNotFoundComponent } from '@shared/page-not-found/page-not-found.component';

import { GalleryAdhocComponent } from '@features/gallery/gallery-adhoc/gallery-adhoc.component';
import { GalleryObservationComponent } from '@features/gallery/gallery-observation/gallery-observation.component';
import { GalleryRegularComponent } from '@features/gallery/gallery-regular/gallery-regular.component';

export const AppRoutes: Routes = [
  ...(isDevMode()
    ? [
        {
          path: 'dev',
          loadChildren: () => import('./dev/dev.routes').then(module => module.devRoutes),
        },
      ]
    : []),
  {
    path: 'assignment',
    component: HomeComponent,
    canActivate: [MsalGuard],
    pathMatch: 'full',
  },
  {
    path: 'userassignment',
    loadChildren: () => import('@features/assignment/assignment.module').then(module => module.AssignmentModule),
    canActivate: [MsalGuard, AuthAdminGuard],
  },
  {
    path: 'assignment/:projectId/:packageId/:subpackageId',
    loadChildren: () => import('@features/checklist/checklist.module').then(module => module.ChecklistModule),
    canActivate: [MsalGuard],
  },
  {
    path: 'handoverassignment',
    loadChildren: () => import('@features/handover/handover-assignment.module').then(module => module.HandoverAssignmentModule),
    canActivate: [MsalGuard, AuthAdminGuard],
  },
  {
    path: 'handoverassignment/:projectId/:packageId/:subpackageId',
    loadChildren: () => import('@features/handover/handover.module').then(module => module.HandoverModule),
    canActivate: [MsalGuard, AuthAdminGuard],
  },
  {
    path: 'gallery/:checklistId/:groupId/:type/:itemId/:inspectionId',
    component: GalleryRegularComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full',
  },
  {
    path: 'gallery/:checklistId/:inspectionId/adhocInspections',
    component: GalleryAdhocComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full',
  },
  {
    path: 'gallery/:assignmentId/:inspectionId/observations',
    component: GalleryObservationComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full',
  },
  {
    path: 'information',
    loadChildren: () => import('@features/information/information.module').then(module => module.InformationModule),
  },
  {
    path: 'download/:exporterType/:fileType/:fileId',
    component: DownloadComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full',
  },
  {
    path: 'master',
    loadChildren: () => import('@features/master/master.module').then(module => module.MasterModule),
    canActivate: [MsalGuard, AuthAdminGuard],
  },
  {
    path: 'exportapi',
    component: ExportApiComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full',
  },
  { path: '', component: LoginComponent },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(AppRoutes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
