<app-navbar></app-navbar>
<div
  class="container"
  *ngIf="authorizationService.authenticated">
  <div class="title">Assigned Projects</div>
  <div
    *ngIf="(status$ | async) === 'loading'"
    class="center">
    <mat-spinner
      [diameter]="24"
      class="mat-spinner-primary-color"></mat-spinner>
  </div>
  <div
    *ngIf="(status$ | async) === 'failed'"
    class="offset-3 col-6">
    <app-alert>
      You do not have access to this application, please visit the tool page
      <a href="https://orsted.sharepoint.com/sites/ToolsPortal/SitePages/Digitial-Inspection.aspx">here</a> for more instructions.
    </app-alert>
  </div>

  <ng-container *ngIf="(status$ | async) === 'success'">
    <div
      *ngIf="(projects$ | async)?.length === 0 && this.authorizationService.isAdminAuthorized()"
      class="offset-3 col-6">
      <app-alert>
        You do not have any assignments yet. You can assign yourself to an assignment <a [routerLink]="['/userassignment']">here</a>.
      </app-alert>
    </div>
    <div
      *ngIf="(projects$ | async)?.length === 0 && !this.authorizationService.isAdminAuthorized()"
      class="offset-3 col-6">
      <app-alert>You do not have any assignments yet. You need to request from your scope manager to add assignments for you.</app-alert>
    </div>
    <div class="row mt-3">
      <div
        class="col-md-6 mb-4"
        *ngFor="let assignment of projects$ | async">
        <mdb-card>
          <mdb-card-body>
            <mdb-card-title>
              <div class="info">
                <div class="project">
                  <span class="label">Project</span>
                  <span class="name">{{ assignment.project.shorthand }}</span>
                </div>
                <div class="project">
                  <mat-icon class="project__icon">arrow_forward</mat-icon>
                </div>
                <div class="project">
                  <span class="label">Package</span>
                  <span class="name">{{ assignment.package_.shorthand }}</span>
                </div>
              </div>
            </mdb-card-title>
            <mdb-card-text>Select a subpackage for {{ assignment.project.name }}</mdb-card-text>
            <div class="flex-row">
              <button
                *ngFor="let subpackage of assignment.subpackages"
                (click)="onProjectClick(assignment, subpackage)">
                {{ subpackage.shorthand }}
              </button>
            </div>
          </mdb-card-body>
        </mdb-card>
      </div>
    </div>
  </ng-container>
</div>
