<div class="ngx-gallery-image-wrapper ngx-gallery-animation-{{ animation }} ngx-gallery-image-size-{{ size }}">
  <div
    class="ngx-gallery-image"
    *ngFor="let image of getImages(); let i = index"
    [ngClass]="{
      'ngx-gallery-active': selectedIndex === image.index,
      'ngx-gallery-inactive-left': selectedIndex > image.index,
      'ngx-gallery-inactive-right': selectedIndex < image.index,
      'ngx-gallery-clickable': clickable
    }"
    [style.background-image]="getSafeUrl(image.src)"
    (click)="handleClick($event, image.index)">
    <div class="ngx-gallery-icons-wrapper">
      <ngx-gallery-action
        *ngFor="let action of actions"
        [icon]="action.icon"
        [disabled]="action.disabled"
        [titleText]="action.titleText"
        (onClick)="action.onClick($event, image.index)"></ngx-gallery-action>
    </div>
    <div
      class="ngx-gallery-image-text"
      *ngIf="showDescription && descriptions[image.index]"
      [innerHTML]="descriptions[image.index]"
      (click)="$event.stopPropagation()"></div>
  </div>
</div>
<ngx-gallery-bullets
  *ngIf="bullets"
  [count]="images.length"
  [active]="selectedIndex"
  (onChange)="show($event)"></ngx-gallery-bullets>
<ngx-gallery-arrows
  class="ngx-gallery-image-size-{{ size }}"
  *ngIf="arrows"
  (onPrevClick)="showPrev()"
  (onNextClick)="showNext()"
  [prevDisabled]="!canShowPrev()"
  [nextDisabled]="!canShowNext()"
  [arrowPrevIcon]="arrowPrevIcon"
  [arrowNextIcon]="arrowNextIcon"></ngx-gallery-arrows>
