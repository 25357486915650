import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { NgxGalleryImage, NgxGalleryOptions, NgxGalleryAnimation } from '@shared/ngx-gallery';

@Component({
  selector: 'app-gallery-dialog',
  templateUrl: './gallery-dialog.component.html',
  styleUrls: ['./gallery-dialog.component.scss'],
})
export class GalleryDialogComponent implements OnInit {
  cancelButtonText = 'Close';
  galleryPictures: NgxGalleryImage[] = [];
  galleryOptions: NgxGalleryOptions[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<GalleryDialogComponent>) {}

  ngOnInit() {
    this.galleryPictures = this.data.observationData;
    this.galleryOptions = [
      {
        imageAnimation: NgxGalleryAnimation.Slide,
      },
      { imageDescription: true, thumbnails: false },
    ];
  }
}
