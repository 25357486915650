<o-button
  [disabled]="disabled"
  [loading]="loading"
  (click)="onClick($event)"
  [variant]="variant"
  [size]="size">
  <ng-content></ng-content>
  <app-badge
    *ngIf="badge"
    [color]="variant"
    [size]="size"
    [inverse]="true"
    slot="suffix">
    {{ badge }}
  </app-badge>
</o-button>
