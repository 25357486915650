import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { UserAssignment } from '@app/models/userAssignment';
import { AppConfig } from '@config';

@Injectable({
  providedIn: 'root',
})
export class UserAssignmentsService {
  constructor(private http: HttpClient, private appConfig: AppConfig) {}

  getAssignments(): Observable<UserAssignment[]> {
    return this.http.get<UserAssignment[]>(this.appConfig.apiUrl + '/admin/v1/userAssignments');
  }
}
