<h2 mat-dialog-title>
  <div style="max-width: 500px; word-break: break-all">{{ data.title }}</div>
</h2>
<mat-dialog-content>
  <ngx-gallery
    [options]="galleryOptions"
    [images]="galleryPictures"></ngx-gallery>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-button
    mat-dialog-close
    class="secondary-button">
    {{ cancelButtonText }}
  </button>
</mat-dialog-actions>
