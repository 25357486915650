<div
  *ngIf="!closed"
  @fadeOut
  [ngClass]="{
    'dit-alert': true,
    'dit-alert--warn': color === 'warn',
    'dit-alert--dismissible': dismissible
  }">
  <ng-content></ng-content>
  <div
    *ngIf="dismissible"
    class="dit-alert__close"
    (click)="onCloseClick()">
    <mat-icon>close</mat-icon>
  </div>
</div>
