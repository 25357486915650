import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ConfirmDialogData } from './confirm-dialog.type';

@Component({
  selector: 'app-confirm-dialogue',
  templateUrl: './confirm-dialogue.component.html',
  styleUrls: ['./confirm-dialogue.component.scss'],
})
export class ConfirmDialogueComponent {
  @Output() confirm = new EventEmitter<boolean>();

  loading: boolean = false;
  title: string;
  content: string;
  confirmationButtonText: string;
  cancelButtonText: string;
  danger: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData, private dialogRef: MatDialogRef<ConfirmDialogueComponent, boolean>) {
    this.title = this.data.title ?? 'Confirmation!';
    this.content = this.data.content ?? 'Are you sure to perform this action?';
    this.confirmationButtonText = this.data.confirmationButtonText ?? 'Confirm';
    this.cancelButtonText = this.data.cancelButtonText ?? 'Cancel';
    this.danger = this.data.danger ?? false;
  }

  onConfirm() {
    if (this.data.useAsync) {
      this.confirm.emit(true);
      return;
    }

    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
