import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { ConfirmDialogueComponent } from '@shared/confirm-dialogue/confirm-dialogue.component';

import { DialogConfig } from '../models/dialog-config';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  openDialog(config: DialogConfig) {
    const dialogRef = this.dialog.open(config.formComponent, {
      data: config.content,
      panelClass: config.panelClass,
      width: config.width ? '500px' : config.width,
      height: config.height ? 'auto' : config.height,
    });
    return dialogRef;
  }

  openEmailNotificationDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'Email Sent',
      content:
        'Due to the large size of the Excel file, the export cannot be generated immediately. Instead the file will be sent to your email once it is ready.',
      confirmationButtonText: 'Ok',
      cancelButtonText: 'Cancel',
    };
    const dialogRef = this.dialog.open(ConfirmDialogueComponent, dialogConfig);
    return dialogRef;
  }
}
