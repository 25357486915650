import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { InspectionSeverity } from '@app/models/checklist';
import { ExporterType, ExportOptions, ExportType, ImageOption, InspectionGrade } from '@app/models/export-options';
import { ExportEmailService } from '@app/services/export-email.service';

@Component({
  selector: 'app-export-dialog',
  templateUrl: './export-dialog.component.html',
  styleUrls: ['./export-dialog.component.scss'],
})
export class ExportDialogComponent {
  public ExporterType = ExporterType;
  public ImageOption = ImageOption;
  public ExportType = ExportType;
  public pdfButtonText = 'Export to PDF';
  public excelButtonText = 'Export to Excel';
  public cancelButtonText = 'Cancel';
  public includeGradeOk = true;
  public includeGradeNotOk = true;
  public includeGradeNa = true;
  public includeGradeNotInspected = true;
  public includeSeverity = [
    { id: InspectionSeverity.Minimum, label: InspectionSeverity.Minimum.toNewSeverityStatus(), value: true },
    { id: InspectionSeverity.Medium, label: InspectionSeverity.Medium.toNewSeverityStatus(), value: true },
    { id: InspectionSeverity.Serious, label: InspectionSeverity.Serious.toNewSeverityStatus(), value: true },
    { id: InspectionSeverity.Critical, label: InspectionSeverity.Critical.toNewSeverityStatus(), value: true },
    { id: InspectionSeverity.Urgent, label: InspectionSeverity.Urgent.toNewSeverityStatus(), value: true },
  ];
  public imageOption = ImageOption.Include;
  public reinspectionOption = 'merge'; // merge|include|exclude

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ExportDialogComponent>,
    private exportEmailService: ExportEmailService
  ) {}

  submitOptions(type: ExportType) {
    const exportOption: ExportOptions = {
      FileType: type,
      PictureOption: this.imageOption,
      InspectionGrades: [],
      InspectionSeverities: [],
      reinspectionOption: this.reinspectionOption,
    };

    if (this.includeGradeOk) {
      exportOption.InspectionGrades.push(InspectionGrade.Ok);
    }
    if (this.includeGradeNotOk) {
      exportOption.InspectionGrades.push(InspectionGrade.NotOk);
    }
    if (this.data.exporterType !== ExporterType.GeneralObservation && this.includeGradeNa) {
      exportOption.InspectionGrades.push(InspectionGrade.Na);
    }
    if (this.data.exporterType !== ExporterType.GeneralObservation && this.includeGradeNotInspected) {
      exportOption.InspectionGrades.push(InspectionGrade.NotInspected);
    }

    this.includeSeverity.forEach(severity => {
      if (severity.value) {
        exportOption.InspectionSeverities.push(severity.id);
      }
    });

    if (this.data.exporterType === ExporterType.Checklists) {
      this.exportEmailService.exportChecklistOverview(this.data.checklistIds, exportOption);
    } else if (this.data.exporterType === ExporterType.Checklist) {
      this.exportEmailService.exportChecklistDetail(this.data.checklistId, exportOption);
    } else if (this.data.exporterType === ExporterType.GeneralObservation) {
      this.exportEmailService.exportGeneralObservation(
        this.data.projectId,
        this.data.packageId,
        this.data.subpackageId,
        this.data.genObservationItemReferenceIds,
        exportOption
      );
    }
    this.dialogRef.close();
  }

  nokChanged(include: boolean) {
    for (const severity in this.includeSeverity) {
      if (Object.prototype.hasOwnProperty.call(this.includeSeverity, severity)) {
        this.includeSeverity[severity].value = include;
      }
    }
  }

  returnZero() {
    return 0;
  }
}
