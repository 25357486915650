import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { ConfirmDialogueComponent } from '@shared/confirm-dialogue/confirm-dialogue.component';

import { ChecklistDetailService } from '@features/checklist/pages/checklist-detail/checklist-detail.service';
import { GeneralObservationService } from '@features/observation/general-observation-overview/general-observation.service';

import { NotificationService } from './notification.service';
import { ExportOptions } from '../models/export-options';

@Injectable({
  providedIn: 'root',
})
export class ExportEmailService {
  constructor(
    private dialog: MatDialog,
    private checklistDetailService: ChecklistDetailService,
    private generalObservationService: GeneralObservationService,
    private notificationService: NotificationService
  ) {}

  exportChecklistOverview(checklistIds: string[], exportOptions: ExportOptions) {
    this.checklistDetailService.emailChecklistsExportFile(checklistIds, exportOptions).subscribe({
      next: () => {
        this.openEmailNotificationDialog();
      },
      error: err => {
        this.showErrorNotificationBar(err);
      },
    });
  }

  exportChecklistDetail(checklistId: string, exportOptions: ExportOptions) {
    this.checklistDetailService.emailChecklistDetailExportFile(checklistId, exportOptions).subscribe({
      next: () => {
        this.openEmailNotificationDialog();
      },
      error: err => {
        this.showErrorNotificationBar(err);
      },
    });
  }

  exportGeneralObservation(
    projectId: string,
    packageId: string,
    subpackageId: string,
    genObservationItemReferenceIds: string[],
    exportOptions: ExportOptions
  ) {
    this.generalObservationService
      .emailGeneralObservationsExportFile(projectId, packageId, subpackageId, genObservationItemReferenceIds, exportOptions)
      .subscribe({
        next: () => {
          this.openEmailNotificationDialog();
        },
        error: err => {
          this.showErrorNotificationBar(err);
        },
      });
  }

  private openEmailNotificationDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'Email Sent',
      content: 'The export cannot be generated immediately. Instead the file will be sent to your email once it is ready.',
      confirmationButtonText: 'Ok',
      cancelButtonText: 'Cancel',
    };
    const dialogRef = this.dialog.open(ConfirmDialogueComponent, dialogConfig);
    return dialogRef;
  }

  private showErrorNotificationBar(_error) {
    this.notificationService.error("Couldn't download file, contact administrator.");
  }
}
