import { HttpClient, HttpBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from '@config';

import { Feedback, FeedbackViewModel, FeedbackVoteViewModel } from './feedback';

@Injectable({
  providedIn: 'root',
})
export class InfoFeedbackService {
  constructor(private http: HttpClient, private handler: HttpBackend, private appConfig: AppConfig) {}

  postFeedback(feedback: Feedback): boolean {
    let success = false;
    const formData: any = new FormData();
    formData.append('Title', feedback.Title);
    formData.append('Description', feedback.Description);
    formData.append('Email', feedback.Email);
    formData.append('Category', feedback.Category);
    formData.append('Type', feedback.Type);
    formData.append('Status', feedback.Status);
    if (feedback.Photos !== undefined) {
      for (const photo of feedback.Photos) {
        formData.append('Photos', photo);
      }
    }
    const url = `${this.appConfig.apiUrl}/admin/v1/Feedbacks/`;
    const http = new HttpClient(this.handler);
    http.post<Feedback>(url, formData, { withCredentials: false }).subscribe(() => {
      success = true;
    });
    return success;
  }

  getFeedback(): Observable<FeedbackViewModel[]> {
    const url = `${this.appConfig.apiUrl}/admin/v1/Feedbacks/`;
    return this.http.get<FeedbackViewModel[]>(url);
  }

  getUserVotes(): Observable<FeedbackVoteViewModel[]> {
    const url = `${this.appConfig.apiUrl}/admin/v1/Feedbacks/GetUserVotes`;
    return this.http.get<FeedbackVoteViewModel[]>(url);
  }

  addVote(feedbackId: string): Observable<FeedbackVoteViewModel> {
    const url = `${this.appConfig.apiUrl}/admin/v1/Feedbacks/${feedbackId}/AddVote`;
    return this.http.post<FeedbackVoteViewModel>(url, null);
  }

  removeVote(feedbackId: string): Observable<FeedbackVoteViewModel> {
    const url = `${this.appConfig.apiUrl}/admin/v1/Feedbacks/${feedbackId}/RemoveVote`;
    return this.http.delete<FeedbackVoteViewModel>(url);
  }
}
