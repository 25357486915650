<div class="login">
  <div class="sidebar">
    <div class="info">
      <title>Digital Inspection Tool</title>
      <p>Digital Inspection Tool (DIT) helps Line managers and Scope managers to share inspection templates across ENG and CONS</p>
      <p>
        You can easily
        <b>Plan</b>
        inspection, see the
        <b>progress</b>
        of inspections and export
        <b>punchlist</b>
      </p>
      <button
        class="secondary-button"
        dialog-button
        (click)="login()">
        Login
      </button>
      <p class="link">
        <a href="/information/list">Announcements</a>
        <img
          src="assets/images/announcement.png"
          height="24" />
      </p>
      <p class="link">
        <a href="/information/faq">FAQs</a>
        <img
          src="assets/images/faq.png"
          height="32" />
      </p>
      <p class="link">
        <a href="/information/addFeedback">Feedback</a>
        <img
          src="assets/images/feedback.png"
          height="24"
          style="padding-left: 2px" />
      </p>
    </div>
  </div>
</div>
