<div [ngClass]="className">
  <table
    [class.table-striped]="striped"
    [class.table-hover]="selectable"
    [class.table-sm]="compact"
    class="table">
    <thead>
      <tr *ngIf="tableColumns && tableColumns.length">
        <th
          *ngFor="let column of tableColumns"
          scope="col"
          [ngStyle]="column.columnStyle ? column.columnStyle : null">
          {{ column.name }}
        </th>
        <!-- action column -->
        <ng-container *ngIf="rowRemovable">
          <th scope="col"></th>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="tableData && tableData.length; else norecord">
        <tr *ngFor="let row of tableData; let i = index">
          <td *ngFor="let column of tableColumns">
            <div *ngIf="!column.cellEditable; else editableCell">
              {{ row[column.dataKey] }}
            </div>
            <ng-template #editableCell>
              <input
                *ngIf="column.cellEditable"
                value="{{ row[column.dataKey] }}"
                type="{{ column.cellEditableType }}"
                (change)="emitEditableCellOnChange(column.dataKey, $event, row)" />
            </ng-template>
          </td>
          <!-- action column -->
          <ng-container *ngIf="rowRemovable">
            <td>
              <div
                class="text-end clickable-link pr-4"
                (click)="emitRowRemoveAction(row)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-trash"
                  viewBox="0 0 16 16">
                  <path
                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                  <path
                    fill-rule="evenodd"
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                </svg>
              </div>
            </td>
          </ng-container>
        </tr>
      </ng-container>
      <ng-template #norecord>
        <tr>
          <td [attr.colspan]="rowRemovable ? tableColumns.length + 1 : tableColumns.length">No Record.</td>
        </tr>
      </ng-template>
    </tbody>
  </table>
</div>
