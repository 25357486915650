import { Component, OnInit, Input, OnChanges } from '@angular/core';

import { BreadcrumbService } from './breadcrumb.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit, OnChanges {
  // TODO: this is poor man's breadcrumb implementation will make this more dynamic so far it serves its purpose.
  breadcrumbString: string;
  @Input() reload?: boolean;

  constructor(private breadcrumbService: BreadcrumbService) {}

  ngOnInit() {
    this.breadcrumbString = this.breadcrumbService.getBreadCrumbs();
  }

  ngOnChanges() {
    if (this.reload) {
      this.breadcrumbString = this.breadcrumbService.getBreadCrumbs();
    }
  }
}
