import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { LocaleDateTimePipe } from './pipes/LocaleDateTime.pipe';
import { DialogService } from './services/dialog.service';

@NgModule({
  imports: [CommonModule, FormsModule, LocaleDateTimePipe],
  exports: [LocaleDateTimePipe],
  declarations: [],
  providers: [DialogService],
})
export class CoreModule {}
