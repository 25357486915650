@if (item | async; as item) {
<div class="d-flex align-items-center border rounded bg-body gap-2 px-2 py-1">
  @if (item.shorthand !== null) {
  <div class="d-flex flex-0 align-items-center">
    <div
      [className]="'badge fw-bold py-0 ' + badgeClass"
      style="line-height: 20px"
      >{{ item.shorthand }}</div
    >
  </div>
  }
  <div class="col">
    {{ item.name }}
  </div>
</div>
} @else {
<div class="d-flex align-items-center border rounded bg-body gap-2 px-2 py-1">
  <p-skeleton
    class="col"
    width="100%"
    height="24px" />
</div>
}
