import { NgClass } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  imports: [NgClass],
  standalone: true,
})
export class BadgeComponent implements OnInit {
  @Input() size: 'medium' | 'large' | 'extra-large' = 'medium';
  @Input() color: 'neutral' | 'primary' | 'info' | 'success' | 'warning' | 'danger' = 'neutral';
  @Input() inverse = false;

  constructor() {}

  get badgeClasses() {
    return {
      'dit-badge': true,
      [`dit-badge--${this.size}`]: true,
      [`dit-badge--${this.color}`]: true,
      'dit-badge--inverse': this.inverse,
    };
  }

  ngOnInit() {}
}
