import { format } from '@formkit/tempo';

const dateFormat = 'DD/MM/YYYY';
const datetimeFormat = 'DD/MM/YYYY HH:mm';
const timezone = 'Europe/Copenhagen';

export function localeDateTime(date: Date, tz: string = timezone): string {
  return format({ date, format: datetimeFormat, tz });
}

export function localeDate(date: Date, tz: string = timezone): string {
  return format({ date, format: dateFormat, tz });
}
