import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class SvgIconsService {
  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {}

  public registerIcons(icons: { [key: string]: string }) {
    for (const [key, url] of Object.entries(icons)) {
      this.iconRegistry.addSvgIconLiteral(key, this.sanitizer.bypassSecurityTrustHtml(url));
    }
  }
}
