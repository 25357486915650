import { Pipe, type PipeTransform } from '@angular/core';

import { localeDateTime } from '@utils/date';

@Pipe({
  name: 'appLocaleDateTime',
  standalone: true,
})
export class LocaleDateTimePipe implements PipeTransform {
  transform(value: Date) {
    return localeDateTime(value);
  }
}
