/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@env';

@Injectable()
export class ApiKeyInterceptor implements HttpInterceptor {
  urls = [environment.apiUrl, environment.storageUrl];
  apiKey = environment.apiKey;

  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.apiKey && this.urls.some(url => request.url.startsWith(url))) {
      const modifiedRequest = request.clone({
        setHeaders: {
          apikey: this.apiKey,
        },
      });
      return next.handle(modifiedRequest);
    }
    return next.handle(request);
  }
}
