import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NgxsModule } from '@ngxs/store';
import { AgGridModule } from 'ag-grid-angular';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { SkeletonModule } from 'primeng/skeleton';

import { CoreModule } from '@app/core.module';
import { NgxGalleryModule } from '@shared/ngx-gallery';

import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ConfirmDialogueComponent } from './confirm-dialogue/confirm-dialogue.component';
import { DetailModule } from './detail/detail.module';
import { DownloadComponent } from './download/download.component';
import { ErrorDialogueComponent } from './error-dialogue/error-dialogue.component';
import { ExportApiComponent } from './export-api/export-api.component';
import { ExportDialogComponent } from './export-dialog/export-dialog.component';
import { GalleryDialogComponent } from './gallery-dialog/gallery-dialog.component';
import { GenericImageGalleryComponent } from './generic-image-gallery/generic-image-gallery.component';
import { HomeComponent } from './home/home.component';
import { ProjectState } from './home/userAssignments.state';
import { LoadingComponent } from './loading/loading.component';
import { LoginComponent } from './login/login.component';
import { NavbarComponent } from './navbar/navbar.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PunchlistComponent } from './punchlist/punchlist.component';
import { ButtonRenderComponent } from './renderers/btn-render/btn-render.component';
import { LinkRenderComponent } from './renderers/link-render/link-render.component';
import { SecuredImageComponent } from './secured-image/secured-image.component';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { StartComponent } from './start/start.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { TableComponent } from './table/table.component';
import { UiModule } from './ui/ui.module';

@NgModule({
  imports: [
    CoreModule,

    // Angular Common Module
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DragDropModule,

    // Angular Material Modules
    MatAutocompleteModule,
    MatBadgeModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,

    // Ag-Grid Module
    AgGridModule,

    // Ngxs Modules
    NgxsModule.forFeature([ProjectState]),

    // PrimeNG Modules
    DropdownModule,
    MultiSelectModule,
    SkeletonModule,

    // MDB Bootstrap Module
    MDBBootstrapModule.forRoot(),

    // DIT Module
    DetailModule,
    NgxGalleryModule,
    UiModule,
  ],
  exports: [
    CoreModule,

    // Angular Common Module
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    // Angular Material Modules
    MatAutocompleteModule,
    MatBadgeModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,

    // Ag-Grid Module
    AgGridModule,

    // PrimeNG Modules
    DropdownModule,
    MultiSelectModule,
    SkeletonModule,

    // DIT Modules
    DetailModule,
    NgxGalleryModule,
    UiModule,

    // MDB Bootstrap Module
    MDBBootstrapModule,

    // DIT Components
    BreadcrumbComponent,
    ButtonRenderComponent,
    ConfirmDialogueComponent,
    ErrorDialogueComponent,
    GenericImageGalleryComponent,
    LinkRenderComponent,
    LoadingComponent,
    NavbarComponent,
    PageNotFoundComponent,
    SecuredImageComponent,
    SnackbarComponent,
    StatisticsComponent,
    TableComponent,
  ],
  declarations: [
    BreadcrumbComponent,
    ButtonRenderComponent,
    ConfirmDialogueComponent,
    DownloadComponent,
    ErrorDialogueComponent,
    ExportApiComponent,
    ExportDialogComponent,
    GalleryDialogComponent,
    GenericImageGalleryComponent,
    HomeComponent,
    LinkRenderComponent,
    LoadingComponent,
    LoginComponent,
    NavbarComponent,
    PageNotFoundComponent,
    PunchlistComponent,
    SecuredImageComponent,
    SnackbarComponent,
    StartComponent,
    StatisticsComponent,
    TableComponent,
  ],
})
export class SharedModule {}
