import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';

import { AuthorizationService } from './authorization.service';

export const AuthRoleGuard: CanActivateFn = (next: ActivatedRouteSnapshot) => {
  const authorizationService = inject(AuthorizationService);
  const router = inject(Router);

  const isAuthorized = authorizationService.authenticated && authorizationService.isAuthorized(next.routeConfig.path);
  if (!isAuthorized && next.data.redirectTo) {
    router.navigate([next.data.redirectTo]);
  }
  return isAuthorized;
};
