<div class="detail-label">
  <div [className]="'detail-label__label-container ' + labelClass">
    @if (label !== null){
    <span class="detail-label__label-text">{{ label }}</span>
    }
  </div>
  <div class="detail-label__content">
    <ng-content></ng-content>
  </div>
</div>
