import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Punchlist } from '@app/models/punchlist';
import { AppConfig } from '@config';

@Injectable({
  providedIn: 'root',
})
export class PunchlistService {
  constructor(private http: HttpClient, private appConfig: AppConfig) {}

  getPunchlist(checklistId: string): Observable<Punchlist> {
    const url = `${this.appConfig.apiUrl}/admin/v1/Checklists/${checklistId}/punchlist`;
    return this.http.get<Punchlist>(url);
  }
}
