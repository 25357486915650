<div class="container">
  <title>Inspection Photos</title>
  <div *ngIf="!isLoaded()">Loading..</div>
  <div *ngIf="isLoaded()">
    <div
      class="left"
      *ngIf="authorizationService.hasSessionRole() && !authorizationService.isAuthorized('gallery.gallery-adhoc')">
      Not authorized.
    </div>
    <div
      class="center"
      *ngIf="!authorizationService.hasSessionRole() || authorizationService.isAuthorized('gallery.gallery-adhoc')">
      <ngx-gallery
        [options]="galleryOptions"
        [images]="photoUrls"></ngx-gallery>
    </div>
  </div>
</div>
