import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface TableColumn {
  name: string;
  dataKey: string;
  columnStyle?: any;
  cellEditable?: boolean;
  cellEditableType?: 'number' | 'text';
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
  @Input() tableColumns: TableColumn[];
  @Input() tableData: any[];
  @Input() className?: string;
  @Input() compact? = true;
  @Input() striped? = false;
  @Input() selectable? = false;
  @Input() rowRemovable? = false;
  @Output() rowRemoveClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() editableCellOnChange: EventEmitter<any> = new EventEmitter<any>();
  constructor() {}

  ngOnInit() {}

  emitRowRemoveAction(row: any) {
    this.rowRemoveClick.emit(row);
  }

  emitEditableCellOnChange(columnKey: string, event: any, row: any) {
    const changedValue = event.target.value;
    this.editableCellOnChange.emit({ columnKey, changedValue, row });
  }
}
