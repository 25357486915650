import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { User } from '@app/models/user';
import { AppConfig } from '@config';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient, private appConfig: AppConfig) {}

  getUser(username: string): Observable<User> {
    const url = `${this.appConfig.apiUrl}/admin/v1/Users?username=${username}`;
    return this.http.get<User>(url);
  }

  getUserById(id: string): Observable<User> {
    const url = `${this.appConfig.apiUrl}/admin/v1/Users/${id}`;
    return this.http.get<User>(url);
  }

  getAdUser(username: string): Observable<User> {
    const url = `${this.appConfig.apiUrl}/admin/v1/Users/${username}/roles`;
    return this.http.get<User>(url);
  }

  createUser(username: string): Observable<User> {
    const url = `${this.appConfig.apiUrl}/admin/v1/Users`;
    return this.http.post<User>(url, {
      username: username,
    });
  }

  updateUserEmail(oldEmail: string, newEmail: string): Observable<any> {
    const url = `${this.appConfig.apiUrl}/admin/v1/Users/username`;
    return this.http.put(url, {
      old: oldEmail,
      new: newEmail,
    });
  }
}
