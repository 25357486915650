<ng-container *ngIf="authorizationService.authenticated">
  <app-navbar></app-navbar>
</ng-container>
<ng-container *ngIf="!authorizationService.authenticated">
  <app-info-nav-bar></app-info-nav-bar>
</ng-container>
<h1>Frequently Asked Questions</h1>
<div
  class="collapse-content faq"
  *ngFor="let faq of FAQs">
  <div
    class="collapse"
    id="{{ faq.class }}">
    <a
      class="{{ faq.color }}"
      href="{{ faq.link }}">
      {{ faq.title }}
    </a>
    <div class="content">
      <div class="inner-content">
        <h5>{{ faq.title }}</h5>
        <div [innerHTML]="faq.content"></div>
      </div>
    </div>
  </div>
</div>
