<ng-container *ngIf="!feedbackFromApp && authorizationService.authenticated && authorizationService.isAuthorized('feedback')">
  <app-navbar></app-navbar>
</ng-container>
<ng-container *ngIf="!feedbackFromApp && (!authorizationService.authenticated || !authorizationService.isAuthorized('feedback'))">
  <app-info-nav-bar></app-info-nav-bar>
</ng-container>
<div class="container">
  <h1 class="mb-4">Feedback Form</h1>
  <div class="mb-3">
    <span class="d-block form-label">
      <label
        title="Only proposals for future development of DIT can be submitted through the feedback module.
If you have questions regarding DIT, please contact the DIT subject matter expert in your area.
If you have problems with the app or have found a bug, please report this to the IT helpdesk">
        Feedback Type
      </label>
      <span class="required">*</span>
      <span class="info-label">( Selecting Feedback type Issue will creates an IT Service Desk ticket )</span>
    </span>
    <div class="col-lg-3">
      <select
        class="form-select"
        (change)="feedbackTypeChange($event.target)"
        aria-label="feedback-type">
        <option selected>Proposal</option>
        <option value="Problem">Issue</option>
      </select>
    </div>
  </div>
  <div class="mb-3">
    <span class="d-block form-label">
      <label title="Please specify what interface of DIT the proposal refers to">Category Type</label>
      <span class="required">*</span>
    </span>
    <div class="col-lg-3">
      <select
        class="form-select"
        (change)="categoryChange($event.target)"
        #categoryLabel
        aria-label="category-type">
        <option [selected]="feedbackFromApp">App</option>
        <option [selected]="!feedbackFromApp">Web</option>
        <option>Database</option>
      </select>
    </div>
  </div>
  <div class="mb-3">
    <span class="d-block form-label">
      <label title="Please provide a concise title">Title</label>
      <span class="required">*</span>
      <span
        class="info-label"
        *ngIf="isFeedbackTypeProblem()">
        ( e.g. DIT - CHW0102 - FOU - FFAB - KR_KAN - HESI - [Orsted Id] - [Contractor Id] )
      </span>
    </span>
    <span>
      <input
        class="form-control"
        id="title"
        style="width: 100%"
        type="text"
        name="title"
        [(ngModel)]="title"
        aria-label="feedback-title" />
    </span>
  </div>
  <div class="mb-3">
    <span class="d-block form-label">
      <label title="Please describe your proposal in details">Description</label>
      <span class="required">*</span>
    </span>
    <span>
      <textarea
        class="form-control"
        rows="5"
        cols="40"
        [(ngModel)]="description"
        aria-label="feedback-description"></textarea>
    </span>
  </div>
  <div class="mb-3">
    <span class="d-block form-label">
      <label
        for="photos"
        title="If applicable please include pictures supporting your proposal">
        Pictures:
      </label>
    </span>
    <div class="col-lg-3">
      <input
        type="file"
        name="photos"
        class="form-control"
        id="formImage"
        accept="image/*"
        multiple
        (change)="handleFile($event)"
        aria-label="picture-browser" />
    </div>
    <div class="photo-container">
      <app-generic-image-gallery
        *ngIf="tempPhotos.length > 0"
        [images]="tempPhotos"></app-generic-image-gallery>
    </div>
  </div>
  <div
    class="mb-3"
    *ngIf="!feedbackFromApp">
    <span class="d-block form-label">
      <label title="Please include your email so the DIT team can follow up">Email</label>
      <span class="required">*</span>
    </span>
    <div class="col-lg-3">
      <input
        class="form-control"
        id="email"
        type="email"
        [(ngModel)]="email" />
    </div>
  </div>
</div>
<div class="actionbar">
  <a>
    <button
      type="button"
      (click)="submitFeedback()">
      Submit and close
    </button>
  </a>
  <a>
    <button
      *ngIf="!feedbackFromApp"
      type="button"
      class="secondary-button"
      (click)="onCancel()"
      aria-label="submit-close-button">
      Cancel
    </button>
  </a>
</div>
