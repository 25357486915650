import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { NotificationService } from '../services/notification.service';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private notificationService: NotificationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        let msg = '';
        if (error.error instanceof ErrorEvent) {
          errorMessage = `Error: ${error.message}`;
        } else {
          if (typeof error.error === 'string') {
            msg = error.error;
          } else if (typeof error.error === 'object') {
            if (error.error?.error && error.error?.error?.message) {
              msg = error.error?.error?.message;
            } else if (error.error && error.error?.message) {
              msg = error.error?.message;
            }
          }

          errorMessage = `Error Code: ${error.status}\nMessage: ${msg}`;

          if (error.status === 401) {
            errorMessage = `Error Code: ${error.status}, Message: Not authorized`;
          }
        }

        if (error.status !== 404) {
          this.notificationService.showNotification([{ text: errorMessage, status: 'error' }], 5000);
        }

        return throwError(() => error);
      })
    );
  }
}
