import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
  MsalService,
  MsalGuard,
  MsalInterceptor,
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  MsalBroadcastService,
  MSAL_INSTANCE,
  MSAL_GUARD_CONFIG,
  MSAL_INTERCEPTOR_CONFIG,
  MsalModule,
} from '@azure/msal-angular';
import { InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';

import { environment } from '@env';

export function MSALInstanceFactory(): IPublicClientApplication {
  const {
    auth: { clientId, authority, redirectUri, postLogoutRedirectUri, cacheLocation, storeAuthStateInCookie },
  } = environment;

  return new PublicClientApplication({
    auth: {
      clientId,
      authority,
      redirectUri,
      postLogoutRedirectUri,
    },
    cache: {
      cacheLocation,
      storeAuthStateInCookie,
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: new Map(environment.auth.protectedResourceMap),
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read'],
    },
  };
}

@NgModule({
  providers: [],
  imports: [MsalModule],
})
export class MsalConfigModule {
  static forRoot() {
    return {
      ngModule: MsalConfigModule,
      providers: [
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
        {
          provide: MSAL_INSTANCE,
          useFactory: MSALInstanceFactory,
        },
        {
          provide: MSAL_GUARD_CONFIG,
          useFactory: MSALGuardConfigFactory,
        },
        {
          provide: MSAL_INTERCEPTOR_CONFIG,
          useFactory: MSALInterceptorConfigFactory,
        },
      ],
    };
  }
}
