import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';

import Util from '@app/common/util';
import { InspectionGrade, InspectionSeverity } from '@app/models/checklist';
import { ExportOptions } from '@app/models/export-options';
import { GeneralObservation, GeneralObservationInspectionInputModel } from '@app/models/general-observation';
import { AppConfig } from '@config';

@Injectable({
  providedIn: 'root',
})
export class GeneralObservationService {
  constructor(private http: HttpClient, private appConfig: AppConfig, private msalService: MsalService) {}

  get userName(): string {
    let activeAccount = this.msalService.instance.getActiveAccount();
    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      const accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
      activeAccount = this.msalService.instance.getActiveAccount();
    }
    return activeAccount.username;
  }

  getGeneralObservations(projectId: string, packageId: string, subpackageId: string): Observable<GeneralObservation[]> {
    const url = `${this.appConfig.apiUrl}/admin/v1/GeneralObservations?projectId=${projectId}&packageId=${packageId}&subpackageId=${subpackageId}`;
    return this.http.get<GeneralObservation[]>(url);
  }

  emailGeneralObservationsExportFile(
    projectId: string,
    packageId: string,
    subpackageId: string,
    genObservationItemReferenceIds: string[],
    exportOption: ExportOptions
  ) {
    const url = `${this.appConfig.apiUrl}/admin/v1/GeneralObservations/export`;
    const data = {
      ProjectId: projectId,
      PackageId: packageId,
      SubpackageId: subpackageId,
      GenObservationItemReferenceIds: genObservationItemReferenceIds,
      ExportOptions: exportOption,
    };
    return this.http.post(url, data);
  }

  createGeneralObservation(
    assignmentId: string,
    inspectionId: string,
    name: string,
    itemReferenceId: string,
    subCategoryId: string,
    inspectionGrade: InspectionGrade,
    inspectionSeverity: InspectionSeverity,
    comment: string,
    contractorId: string,
    observationId: string
  ) {
    const url = `${this.appConfig.apiUrl}/admin/v1/GeneralObservations/${assignmentId}/inspections/${inspectionId}`;

    const data: GeneralObservationInspectionInputModel = {
      name: name,
      generalObservationItemReference: itemReferenceId,
      generalObservationSubCategoryId: subCategoryId,
      clientCreationTimestamp: new Date(),
      inspectionGrade: inspectionGrade,
      inspectionSeverity: inspectionSeverity,
      comment: comment,
      generalObservationContractorId: contractorId,
      observationId: observationId,
      createdByUser: this.userName,
    };

    return this.http.post<GeneralObservationInspectionInputModel>(url, data);
  }

  uploadGeneralObservationInspectionPhoto(assignmentId: string, inspectionId: string, metadataId: string, photo: File) {
    const url = `${this.appConfig.apiUrl}/admin/v1/GeneralObservations/${assignmentId}/inspections/${inspectionId}/photos/${metadataId}`;

    return this.http.post(url, photo, Util.getHttpFileUploadOptions());
  }
}
