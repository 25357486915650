import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { AuthorizationService } from './authorization.service';

export const AuthAdminGuard: CanActivateFn = () => {
  const authorizationService = inject(AuthorizationService);

  return authorizationService.isAdminAuthorized();
};
