import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private storage: Storage;

  constructor() {
    this.storage = sessionStorage;
  }

  getData(key: string): string {
    return this.storage.getItem(key);
  }

  saveData(key: string, value: string): void {
    this.storage.setItem(key, value);
  }

  removeData(key: string): void {
    this.storage.removeItem(key);
  }

  hasData(key: string): boolean {
    return this.storage.getItem(key) != null;
  }
}
