import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { AuthorizationService } from '@app/services/authorization/authorization.service';
import { NotificationService } from '@app/services/notification.service';

import { DownloadService } from './download.service';

declare global {
  interface Navigator {
    msSaveBlob: (blob: Blob, fileName: string) => boolean;
  }
}

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss'],
})
export class DownloadComponent implements OnInit {
  pdfSource: SafeResourceUrl;
  isLoadCompleted = false;
  isFailedToDownload = false;
  isPdfType = false;
  exporterType = this.route.snapshot.params.exporterType;
  fileType = this.route.snapshot.params.fileType;
  fileId = this.route.snapshot.params.fileId;

  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private downloadService: DownloadService,
    public authorizationService: AuthorizationService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.downloadService.getFile(this.exporterType, this.fileType, this.fileId).subscribe({
      next: res => {
        this.isLoadCompleted = true;
        if (navigator.msSaveBlob) {
          navigator.msSaveBlob(res, `${this.fileId}.${this.fileType}`);
        } else if (this.fileType === 'pdf') {
          this.isPdfType = true;
          this.pdfSource = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(res));
        } else {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(res);
          link.download = `${this.fileId}.${this.fileType}`;
          document.body.appendChild(link);
          link.click();
        }
      },
      error: () => {
        this.isLoadCompleted = true;
        this.isFailedToDownload = true;
        this.notificationService.error("Couldn't download file, contact administrator.");
      },
    });
  }
}
