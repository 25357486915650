import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, BehaviorSubject } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

// Inspired by https://blog.strongbrew.io/safe-image-requests-in-angular

@Component({
  selector: 'app-secured-image',
  templateUrl: './secured-image.component.html',
  styleUrls: ['./secured-image.component.scss'],
})
export class SecuredImageComponent implements OnInit, OnChanges {
  @Input() private src: string;
  private src$: BehaviorSubject<string>;

  @Input() height: string;
  @Input() width: string;

  // this stream will contain the actual url that our img tag will load
  // every time the src changes, the previous call would be canceled and the
  // new resource would be loaded
  dataUrl$: Observable<any>;

  // we need HttpClient to load the image and DomSanitizer to trust the url
  constructor(private httpClient: HttpClient, private domSanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.src$ = new BehaviorSubject(this.src);
    this.dataUrl$ = this.src$.pipe(switchMap(url => this.loadImage(url)));
  }

  // This part just creates an rxjs stream from the src
  // this makes sure that we can handle it when the src changes
  // or even when the component gets destroyed
  ngOnChanges(): void {
    this.src$.next(this.src);
  }

  private loadImage(url: string): Observable<any> {
    return this.httpClient.get(url, { responseType: 'blob' }).pipe(
      map(e => {
        return this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(e));
      })
    );
  }
}
