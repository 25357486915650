import { InspectionGrade, InspectionSeverity } from '../models/checklist';

export default class Util {
  static fromInspectionGradeStatus(grade: InspectionGrade) {
    const inspectionGrade = grade.toString().toUpperCase();
    if (inspectionGrade === InspectionGrade[InspectionGrade.OK].toString().toUpperCase()) {
      return 'OK';
    } else if (inspectionGrade === InspectionGrade[InspectionGrade.NOK].toString().toUpperCase()) {
      return 'NOT OK';
    } else if (inspectionGrade === InspectionGrade[InspectionGrade.NA].toString().toUpperCase()) {
      return 'N/A';
    }
    return '';
  }

  static toInspectionGradeStatus(inspectionStatus: string) {
    if (inspectionStatus === 'OK') {
      return InspectionGrade.OK;
    } else if (inspectionStatus === 'NOK') {
      return InspectionGrade.NOK;
    } else if (inspectionStatus === 'N/A') {
      return InspectionGrade.NA;
    }
    return InspectionGrade.NOK;
  }

  static getHttpFileUploadOptions() {
    const options = {
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    };
    return options;
  }

  static toInspectionSeverity(severityStatus?: string): InspectionSeverity {
    if (!severityStatus) {
      return InspectionSeverity.Serious;
    }

    if (severityStatus.includes('Severity 1')) {
      return InspectionSeverity.Minimum;
    } else if (severityStatus.includes('Severity 2')) {
      return InspectionSeverity.Medium;
    } else if (severityStatus.includes('Severity 4')) {
      return InspectionSeverity.Critical;
    } else if (severityStatus.includes('Severity 5')) {
      return InspectionSeverity.Urgent;
    } else {
      return InspectionSeverity.Serious;
    }
  }

  static gridDateComparator(filterLocalDateAtMidnight, cellValue) {
    const dateAsString = cellValue;
    if (dateAsString == null) {
      return 0;
    }

    // dates are stored as yyyy-mm-dd
    // We create a Date object for comparison against the filter date
    const dateParts = dateAsString.split('-');
    const day = Number(dateParts[2].substring(0, 2));
    const month = Number(dateParts[1]) - 1;
    const year = Number(dateParts[0]);
    const cellDate = new Date(year, month, day);

    // Now that both parameters are Date objects, we can compare
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    } else if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    } else {
      return 0;
    }
  }
}
