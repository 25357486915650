import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { ColDef, GridApi } from 'ag-grid-community';
import { Observable } from 'rxjs';

import { AuthorizationService } from '@app/services/authorization/authorization.service';
import { ConfirmDialogueComponent } from '@shared/confirm-dialogue/confirm-dialogue.component';
import { localeDateTime } from '@utils/date';

import { Measurement } from './measurement';
import { MeasurementDetailDialogComponent } from './measurement-detail-dialog/measurement-detail-dialog.component';
import { DeleteMeasurement, GetMeasurements } from './measurements.action';
import { MeasurementState } from './measurements.state';

@Component({
  selector: 'app-measurements',
  templateUrl: './measurements.component.html',
  styleUrls: ['./measurements.component.scss'],
})
export class MeasurementsComponent implements OnInit {
  columnDefs: ColDef[] = [
    { headerName: 'SHORTHAND', field: 'shorthand' },
    { headerName: 'NAME', field: 'name' },
    { headerName: 'ADDITIONAL INFO', field: 'additionalInformation' },
    { headerName: 'UNIT', field: 'unit' },
    {
      headerName: 'CREATED DATE',
      field: 'created',
      valueFormatter: data => {
        return localeDateTime(data.value);
      },
      width: 120,
    },
    {
      headerName: 'UPDATED DATE',
      field: 'updated',
      sort: 'desc',
      valueFormatter: data => {
        return localeDateTime(data.value);
      },
      width: 120,
    },
    {
      headerName: 'ACTION',
      editable: false,
      colId: 'action',
      width: 70,
      suppressSizeToFit: false,
      cellRenderer: function () {
        const actionDiv = document.createElement('div');
        actionDiv.innerHTML = `
        <svg class='clickable-link' data-action="edit" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
          <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
        </svg>
        <svg class='clickable-link' data-action="delete" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
          <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
        </svg>`;
        return actionDiv;
      },
    },
  ];

  defaultColDef = {
    filter: false,
    sortable: true,
    resizable: false,
    suppressHeaderMenuButton: true,
  };

  frameworkComponents: any;
  domLayout = 'autoHeight';
  rowSelection = 'multiple';
  context = { componentParent: this };
  private gridApi: GridApi;

  @Select(MeasurementState.getMeasurements) measurements$: Observable<Measurement[]>;

  constructor(private store: Store, private dialog: MatDialog, public authorizationService: AuthorizationService) {}

  ngOnInit() {
    this.store.dispatch(new GetMeasurements());
  }

  onGridReady($param) {
    this.gridApi = $param.api;
    this.gridApi.sizeColumnsToFit();
  }

  onGridSizeChanged() {
    this.gridApi.sizeColumnsToFit();
  }

  onSearch($param) {
    this.gridApi.setGridOption('quickFilterText', $param);
  }

  onCellClicked($param) {
    if ($param.column.colId === 'action' && $param.event.target.dataset.action) {
      const action = $param.event.target.dataset.action;
      if (action === 'edit') {
        this.edit($param.node.data);
      }
      if (action === 'delete') {
        if ($param.node.allChildrenCount > 0) {
          this.delete($param.node);
          return;
        }
        this.delete($param.node.data);
      }
    }
  }

  create() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'Create Measuring Point',
      action: 'create',
    };
    dialogConfig.width = '500px';
    this.dialog.open(MeasurementDetailDialogComponent, dialogConfig);
  }

  edit($param) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'Edit Measuring Point',
      action: 'update',
      measurement: $param,
    };
    dialogConfig.width = '500px';
    this.dialog.open(MeasurementDetailDialogComponent, dialogConfig);
  }

  delete($param) {
    const measurementId = $param.id;
    if (measurementId !== undefined) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        title: 'Delete Measuring Point',
        content: 'Are you sure you want to Delete this Measuring Point?',
        confirmationButtonText: 'Delete',
        cancelButtonText: 'Cancel',
      };
      const confirmDialogueRef = this.dialog.open(ConfirmDialogueComponent, dialogConfig);
      confirmDialogueRef.afterClosed().subscribe(isConfirmed => {
        if (isConfirmed) {
          this.store.dispatch(new DeleteMeasurement({ measurementId: measurementId.toString() }));
        }
      });
    }
  }
}
