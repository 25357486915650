<div class="container">
  <title>Download File</title>
  <div *ngIf="!isLoadCompleted">Loading..</div>
  <div *ngIf="isLoadCompleted">
    <div *ngIf="authorizationService.hasSessionRole() && !authorizationService.isAuthorized('download')">Not authorized.</div>
    <div *ngIf="authorizationService.isAuthorized('download')">
      <iframe
        *ngIf="!isFailedToDownload && isPdfType"
        [src]="pdfSource"
        title="PDF Viewer"
        class="pdf-frame"
        frameborder="0"
        scrolling="no"
        height="100%"
        width="100%"></iframe>
      <div *ngIf="!isFailedToDownload && !isPdfType">You can save the excel file from your web browser now.</div>
    </div>
  </div>
</div>
