import { HttpClient, HttpBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from '@config';

@Injectable({
  providedIn: 'root',
})
export class GalleryService {
  constructor(private http: HttpClient, private appConfig: AppConfig, private handler: HttpBackend) {}

  getRegularInspectionPhotos(
    checklistId: string,
    groupId: string,
    itemId: string,
    inspectionId: string,
    includePreviousInspections: boolean
  ): Observable<any[]> {
    const url = `${this.appConfig.apiUrl}/admin/v1/Checklists/${checklistId}/groups/${groupId}/items/${itemId}/regularInspections/${inspectionId}?includePreviousInspections=${includePreviousInspections}`;
    return this.http.get<any[]>(url);
  }

  getRegularInspectionDrawings(
    checklistId: string,
    groupId: string,
    itemId: string,
    inspectionId: string,
    includePreviousInspections: boolean
  ): Observable<any[]> {
    const url = `${this.appConfig.apiUrl}/admin/v1/Checklists/${checklistId}/groups/${groupId}/items/${itemId}/drawingInspections/${inspectionId}?includePreviousInspections=${includePreviousInspections}`;
    return this.http.get<any[]>(url);
  }

  getAdhocInspectionPhotos(checklistId: string, inspectionId: string, includePreviousInspections: boolean): Observable<any[]> {
    const url = `${this.appConfig.apiUrl}/admin/v1/Checklists/${checklistId}/adhocInspections/${inspectionId}?includePreviousInspections=${includePreviousInspections}`;
    return this.http.get<any[]>(url);
  }

  getObservationInspectionPhotos(assignmentId: string, inspectionId: string, includePreviousInspections: boolean): Observable<any[]> {
    const url = `${this.appConfig.apiUrl}/admin/v1/GeneralObservations/${assignmentId}/inspections/${inspectionId}?includePreviousInspections=${includePreviousInspections}`;
    return this.http.get<any[]>(url);
  }

  getSubRegularInspectionPhotos(
    checklistId: string,
    groupId: string,
    itemId: string,
    inspectionId: string,
    includePreviousInspections: boolean
  ): Observable<any[]> {
    const url = `${this.appConfig.apiUrl}/admin/v1/Checklists/${checklistId}/groups/${groupId}/items/${itemId}/subRegularInspections/${inspectionId}/photos?includePreviousInspections=${includePreviousInspections}`;
    return this.http.get<any[]>(url);
  }

  getSubRegularInspectionDrawings(
    checklistId: string,
    groupId: string,
    itemId: string,
    inspectionId: string,
    includePreviousInspections: boolean
  ): Observable<any[]> {
    const url = `${this.appConfig.apiUrl}/admin/v1/Checklists/${checklistId}/groups/${groupId}/items/${itemId}/subRegularInspections/${inspectionId}/drawings?includePreviousInspections=${includePreviousInspections}`;
    return this.http.get<any[]>(url);
  }
}
